//import React from 'react';
import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
//import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import foto from '../../assets/deneme.jpg';
import { useToken } from '../useToken';
import {Link , useParams} from 'react-router-dom'
import { Line } from "react-chartjs-2";

//import MapComponent from './mapElement'
import MapComponent from './mapComponent';
//import Map from './generalMap'
import Modal from '../../pages/inverterPageMain/settings';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const dataasd = {
  labels: [
    '2023-04-15', '2023-04-20', '2023-04-25', '2023-04-30', '2023-05-05', 
    '2023-05-10', '2023-05-15', '2023-05-20', '2023-05-25', '2023-05-30', 
    '2023-06-05', '2023-06-10', '2023-06-15', '2023-06-20', '2023-06-25', 
    '2023-06-30', '2023-07-05', '2023-07-10', '2023-07-15', '2023-07-20', 
    '2023-07-25', '2023-07-30', '2023-08-05', '2023-08-10', '2023-08-15', 
    '2023-08-20', '2023-08-25', '2023-08-30', '2023-09-05', '2023-09-10', 
    '2023-09-15', '2023-09-20'
  ],
  datasets: [
    {
      label: 'Actual Vegetation',
      data: [0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.90, 0.90, 0.85, 0.80, 0.75, 0.70, 0.65, 0.60, 0.55, 0.50, 0.40, 0.35, 0.30, 0.25, 0.20],
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    },
    {
      label: 'Target Vegetation',
      data: [0.19, 0.26, 0.29, 0.39, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.70, 0.80, 0.90, 1.00, 1.10, 1.20, 1.30, 1.35, 1.40, 1.35, 1.30, 1.25, 1.20, 1.10, 1.00, 0.95, 0.90, 0.80, 0.75, 0.70, 0.60, 0.55],
      fill: false,
      borderColor: 'rgb(192, 75, 75)',
      tension: 0.1
    },
    {
      label: 'Past Vegetation',
      data: [0.01, 0.3, 0.10, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.75, 0.75, 0.75, 0.70, 0.65, 0.60, 0.55, 0.50, 0.45, 0.40, 0.35, 0.30, 0.25, 0.20, 0.15, 0.10],
      fill: false,
      borderColor: 'rgb(111, 23, 102)',
      tension: 0.1
    }
  ]
};
const dataWater = {
  labels: [
    '2023-04-15', '2023-04-20', '2023-04-25', '2023-04-30', '2023-05-05', 
    '2023-05-10', '2023-05-15', '2023-05-20', '2023-05-25', '2023-05-30', 
    '2023-06-05', '2023-06-10', '2023-06-15', '2023-06-20', '2023-06-25', 
    '2023-06-30', '2023-07-05', '2023-07-10', '2023-07-15', '2023-07-20', 
    '2023-07-25', '2023-07-30', '2023-08-05', '2023-08-10', '2023-08-15', 
    '2023-08-20', '2023-08-25', '2023-08-30', '2023-09-05', '2023-09-10', 
    '2023-09-15', '2023-09-20'
  ],
  datasets: [
    {
      label: 'Actual Water',
      data: [0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.90, 0.90, 0.85, 0.80, 0.75, 0.70, 0.65, 0.60, 0.55, 0.50, 0.40, 0.35, 0.30, 0.25, 0.20],
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    },
    {
      label: 'Target Water',
      data: [0.19, 0.26, 0.29, 0.39, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.70, 0.80, 0.90, 1.00, 1.10, 1.20, 1.30, 1.35, 1.40, 1.35, 1.30, 1.25, 1.20, 1.10, 1.00, 0.95, 0.90, 0.80, 0.75, 0.70, 0.60, 0.55],
      fill: false,
      borderColor: 'rgb(192, 75, 75)',
      tension: 0.1
    },
    {
      label: 'Past Water',
      data: [0.01, 0.3, 0.10, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.75, 0.75, 0.75, 0.70, 0.65, 0.60, 0.55, 0.50, 0.45, 0.40, 0.35, 0.30, 0.25, 0.20, 0.15, 0.10],
      fill: false,
      borderColor: 'rgb(111, 23, 102)',
      tension: 0.1
    }
  ]
};

function SattImageMain() {
  //const [selectedOption, setSelectedOption] = useState(null);
  const [typeSelection, setTypeSelection] = useState(""); 
  const [typeSelectionIm1, setTypeSelectionIm1] = useState(""); 
  const [typeSelectionIm2, setTypeSelectionIm2] = useState(""); 
  const [typeValue, setTypeValue] = useState('');
  const [dateValue, setDateValue] = useState('');
  const [dateValueIm1, setDateValueIm1] = useState('');
  const [dateValueIm2, setDateValueIm2] = useState('');
  const [dateSelection, setDateSelection] = useState('');
  const [isComparisonView, setIsComparisonView] = useState(false);
  const [isNDMIView, setIsNDMIView] = useState(false);
  const [isNDMIViewIm1, setIsNDMIViewIm1] = useState(false);
  const [isNDMIViewIm2, setIsNDMIViewIm2] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const [compareImage1, setcompareImage1] = useState(null);
  const [compareImage2, setcompareImage2] = useState(null);
  //const [selectedType, setSelectedType] = useState("NDVI");

  const navigate = useNavigate(); // <-- added this line

 

  const{ id } = useParams();
  const token = useToken()
  //console.log(token)

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageURL, setImageURL] = useState('');
  const [imageURLndmi, setImageURLndmi] = useState('');
  
  //const [imageURL, setImageURL] = useState(data.length > 0 ? data[0].ndvi : null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const headers = new Headers({
          'Authorization': `Token ${token}`
        });

        const response = await fetch(`https://nabuapplication.com/api/satelliteimage/${id}`, { headers });
        const data = await response.json();
        setData(data);
        const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        const latestImageData = sortedData[0];
        const latestImageURL = latestImageData.ndvi ;
        const latestImageURLndmi = latestImageData.ndmi ;
        setImageURL(latestImageURL);
        setImageURLndmi(latestImageURLndmi)
        setSelectedOption({
          id: latestImageData.id,
          name: latestImageData.name,
          date: latestImageData.date,
          imageURL: latestImageURL,
          ndmiURL:latestImageURLndmi
        });
        setcompareImage1({
          id: latestImageData.id,
          name: latestImageData.name,
          date: latestImageData.date,
          imageURL: latestImageURL,
          ndmiURL:latestImageURLndmi
        });
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    if (token) {
      fetchData();
    }
  }, [id, token]);

  if (!data) {
    return <p>Loading...</p>;
  }

    // create a new array of objects that includes the date property
    const options = data.map((item) => {
      return {
        id: item.id,
        name: item.name,
        date: item.date,
        imageURL: item.ndvi, // or item.ndmi, depending on which image you want to display
        //ndviURL: item.ndvi,
        ndmiURL: item.ndmi,

      };
    });

    const imageData = data.map((item) => {
      return {
        id: item.id,
        name: item.name,
        date: item.date,
        imageURL: item.ndvi, // or item.ndmi, depending on which image you want to display
        //ndviURL: item.ndvi,
        ndmiURL: item.ndmi,

      };
    });

    

    const handleDateChange = (event) => {
      setDateValue(event.target.value);
      const selectedId = event.target.value;
      const selectedOption = options.find((option) => option.id === selectedId);
      const selectedNewOption = imageData.find((imageData) => imageData.id === selectedId);
      const compareImage1 = imageData.find((option) => option.id === selectedId);
      setSelectedOption(selectedOption);
      setcompareImage1(compareImage1);
    };

   const handleTypeChange = (event) => {
    setTypeSelection(event.target.value);
  const selectedType = event.target.value;
  if (selectedType === 1) {
    setIsNDMIView(true);
  } else if (selectedType === 2) {
    setIsNDMIView(false);
  }
};
function handleViewChange(event) {
  setTypeValue(event.target.value);
  if (event.target.value === 20) {
    setIsComparisonView(true);
  } else {
    setIsComparisonView(false);
  }
}
const handleDateChangeImage1 = (event) => {
  setDateValueIm1(event.target.value);
  const selectedId = event.target.value;
  //const selectedOption = options.find((option) => option.id === selectedId);
  const selectedNewOption = imageData.find((imageData) => imageData.id === selectedId);
  const compareImage1 = imageData.find((option) => option.id === selectedId);
  //setSelectedOption(selectedOption);
  setcompareImage1(compareImage1);
};
const handleTypeChangeIm1 = (event) => {
  setTypeSelectionIm1(event.target.value);
const selectedType = event.target.value;
if (selectedType === 1) {
  setIsNDMIViewIm1(true);
} else if (selectedType === 2) {
  setIsNDMIViewIm1(false);
}
};
const handleDateChangeImage2 = (event) => {
  setDateValueIm2(event.target.value);
  const selectedId = event.target.value;
  const selectedOption = options.find((option) => option.id === selectedId);
  const selectedNewOption = imageData.find((imageData) => imageData.id === selectedId);
  const compareImage1 = imageData.find((option) => option.id === selectedId);
  setSelectedOption(selectedOption);
  setcompareImage2(compareImage2);
};
const handleTypeChangeIm2 = (event) => {
  setTypeSelectionIm2(event.target.value);
const selectedType = event.target.value;
if (selectedType === 1) {
  setIsNDMIViewIm2(true);
} else if (selectedType === 2) {
  setIsNDMIViewIm2(false);
}
};

const label = typeSelection === 1 ? "NDVI" : "NDMI";
  

  return (
    <div>
    <div className='WStitle'>Field Name       {data[data.length - 1].crop_type}         {data[data.length - 1].dimension} Ha</div> 
    <div className='sattImageMain'>
      <div className='sattImageImaPart'>
        <div className='sattImaDropDown'>
          <div></div>
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select View Type</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={typeValue}
            label="Select the Available Data Duration"
            onChange={handleViewChange}
            style={{ backgroundColor: '#f2f2f2' }}
          >

            
            <MenuItem value={10}>Single View</MenuItem>
            <MenuItem value={20}>Compare View</MenuItem>
            
            
          </Select>
        </FormControl>
          <div></div>
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select Index</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={typeSelection}
            label="Select the Available Data Duration"
            onChange={handleTypeChange}
            style={{ backgroundColor: '#f2f2f2' }}
          >

            
            <MenuItem value={1}>Vegetation (NDVI)</MenuItem>
            <MenuItem value={2}>Water Content (NDMI)</MenuItem>
            
          </Select>
        </FormControl>
          <div></div>
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select Date</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={dateValue}
            label="Select the Available Data Duration"
            onChange={handleDateChange}
            style={{ backgroundColor: '#f2f2f2' }}
          >

            
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.date}
            </MenuItem>
          ))}
            
          </Select>
        </FormControl>

        </div>
        <div></div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/*<img className= "sattImaMainIma" src={foto}/>
          <img className= "sattImaMainIma" src={imageURL} alt="Latest NDVI or NDMI" />
          <img className="sattImaMainIma" src={selectedOption?.imageURL} alt="Latest NDVI or NDMI" />
          
          {typeSelection === 1 ? (
              <img className="sattImaMainIma" src={selectedOption?.imageURL} alt="Latest NDVI" />
            ) : (
              <img className="sattImaMainIma" src={selectedOption?.ndmiURL} alt="Latest NDMI" />
            )}*/}
            {isComparisonView ? (
              /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// above up to compare view
        <div className='compareviewTop'>
          <div>
          
          <div className='sattImageCompareSelectDropDown' style={{ display: "grid", placeItems: "center" }}>
          <FormControl fullWidth style={{ width: "80%",marginBottom: "20px" }}>
          <InputLabel id="demo-simple-select-label">Select Date</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={dateValueIm1}
            label="Select the Available Data Duration"
            onChange={handleDateChangeImage1}
            style={{ backgroundColor: '#f2f2f2' }}
          >

            
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.date}
            </MenuItem>
          ))}
            
          </Select>
        </FormControl>
        <div></div>
        <FormControl fullWidth style={{ width: "80%",marginBottom: "20px" }}>
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={typeSelectionIm1}
            label={label}
            onChange={handleTypeChangeIm1}
            style={{ backgroundColor: "#f2f2f2" }}
          >
            <MenuItem value={1}>NDVI</MenuItem>
            <MenuItem value={2}>NDMI</MenuItem>
          </Select>
        </FormControl>
        </div>
        {isNDMIViewIm1 ? (
        <img className="compareview" src={compareImage1?.imageURL} alt="Latest NDVI" /> 
        ):( /*<img className="compareview" src={compareImage1?.ndmiURL} alt="Latest NDVI" /> <Map/>*/<div/>
        
        )}
          
          </div>
          <div></div>
          <div>
          <div className='sattImageCompareSelectDropDown' style={{ display: "grid", placeItems: "center" }}>
          <FormControl fullWidth style={{ width: "80%",marginBottom: "20px" }}>
          <InputLabel id="demo-simple-select-label">Select Date</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={dateValueIm2}
            label="Select the Available Data Duration"
            onChange={handleDateChangeImage2}
            style={{ backgroundColor: '#f2f2f2' }}
          >

            
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.date}
            </MenuItem>
          ))}
            
          </Select>
        </FormControl>
        <div></div>
        <FormControl fullWidth style={{ width: "80%",marginBottom: "20px" }}>
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={typeSelectionIm2}
            label={label}
            onChange={handleTypeChangeIm2}
            style={{ backgroundColor: "#f2f2f2" }}
          >
            <MenuItem value={1}>NDVI</MenuItem>
            <MenuItem value={2}>NDMI</MenuItem>
          </Select>
        </FormControl>
        </div>
        {isNDMIViewIm2 ? (
          <img className="compareview" src={selectedOption?.imageURL} alt="Latest NDVI" />
          ):(<img className="compareview" src={selectedOption?.ndmiURL} alt="Latest NDVI" />)}
          </div>
          {/*isNDMIView && 
          <div><img className="" src={selectedOption?.imageURL} alt="Latest NDMI" />
                <img className="" src={selectedOption?.ndmiURL} alt="Latest NDMI" /></div>*/}
        </div>
        
      ) : (
        isNDMIView ? (
          <div>
            <img className="sattImaMainIma" src={selectedOption?.imageURL} alt="Latest NDVI" />
          </div>
        ) : (
          //<img className="sattImaMainIma" src={selectedOption?.ndmiURL} alt="Latest NDVI" />
          <MapComponent></MapComponent>
        )
      )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div> Scale</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end'}}>
        <div className='Resolution'>
          <h11>Resolution 10m - Images provided by ESA Sentinel</h11>
          
          </div>
        </div>
      </div>
      <div></div>
      <div className='sattImaDataPart'>
        <div className='sattImaDataPartWrap'>
          <div className='sattImageTitles'>
          <h3> Average Vegetation: {data[data.length - 1].average.toFixed(2)}</h3> 
          <h3> Max: {data[data.length - 1].maximum.toFixed(2)}</h3>
          <h3> Min: {data[data.length - 1].minimum.toFixed(2)}</h3>
          <h3> Median: {data[data.length - 1].median.toFixed(2)}</h3>
          <h3> Crop Status</h3>
          </div>
          <div className='sattImageGraphs'>
            <Line data={dataasd} />
          </div>
          <br></br>
          <h3> Water Content Status</h3>
          <div className='sattImageGraphs'>
            <Line data={dataWater} />
          </div>
        </div>
      </div>   
    </div>
    <div className='willbedeleted'>
      
    </div>
    </div>
    
  )
}

export default SattImageMain;
