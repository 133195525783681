import React from 'react';
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import NotificationsRounded from '@mui/icons-material/NotificationsRounded';

const styles = {
  menu: {
    position: 'absolute',
    top: '50px', // Adjust the top position as needed
    right: '0',
    zIndex: '999', // Ensure the menu is above other content
    backgroundColor: '#E7E7E8', // Add your background color here
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.32)', // Optional box shadow
  },
};

function AccountMenuItem({ onClick }) {
  return (
    <div style={styles.menu}>
      <MenuItem onClick={onClick}>
        <ListItemIcon>
          <NotificationsRounded fontSize="small" />
        </ListItemIcon>
        Notifications
      </MenuItem>
      <MenuItem onClick={onClick}>
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        Settings
      </MenuItem>
      <MenuItem onClick={onClick}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </div>
  );
}

export default AccountMenuItem;
