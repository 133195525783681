//import * as React from 'react';
//import Box from '@mui/material/Box';
//import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

/*interface LinkTabProps {
  label?: string;
  href?: string;
}*/

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}




function MainFieldNavBar() {

  
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const [selectedItem, setSelectedItem] = useState(null);

    const handleClick = (index) => {
      setSelectedItem(index);
    };
  
    return (
      <div value={value} onChange={handleChange} className='MainFieldNavBar'>
        <Link to="/home/sattview">
          <div className={`sidebartitleMainPart ${selectedItem === 0 ? 'selected' : ''}`} onClick={() => handleClick(0)} label="Page Three" href="/spam" color='red'>
            Maps
          </div>
        </Link>
        <div className='main_header_div'></div>
        <div className={`sidebartitleMainPart ${selectedItem === 1 ? 'selected' : ''}`} onClick={() => handleClick(1)} label="Page Three" href="/spam" color='red'>
          Sensors
        </div>
        <div className='main_header_div'></div>
        <Link to="/home/inverter">
          <div className={`sidebartitleMainPart ${selectedItem === 2 ? 'selected' : ''}`} onClick={() => handleClick(2)} label="Page Three" href="/spam" color='red'>
            Irrigation
          </div>
        </Link>
        <div className='main_header_div'></div>
        <div className={`sidebartitleMainPart ${selectedItem === 3 ? 'selected' : ''}`} onClick={() => handleClick(3)} label="Page Three" href="/spam" color='red'>
          Weather
        </div>
        <div className='main_header_div'></div>
        <Link to="/home/pivotcontrol">
        <div className={`sidebartitleMainPart ${selectedItem === 4 ? 'selected' : ''}`} onClick={() => handleClick(4)} label="Page Three" href="/spam" color='red'>
          AI
        </div>
        </Link>
      </div>
    );
  }

export default MainFieldNavBar;
