import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { MapContainer, TileLayer, Polygon, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';

function SetViewToBounds({ polygonCoordinatesUp }) {
  const map = useMap();
  useEffect(() => {
    if (polygonCoordinatesUp.length > 0) {
      const bounds = new L.LatLngBounds(polygonCoordinatesUp.map(polygon => polygon.coordinates).flat());
      map.fitBounds(bounds);
    }
  }, [map, polygonCoordinatesUp]);
  
  return null;
}

function MapViewMulti({ navigateToSatView, polygonCoordinatesUp }) {
  const mapRef = useRef(null);

  return (
    <MapContainer
      center={[0, 0]} // Initial center is irrelevant because bounds will dictate the view
      zoom={15}
      style={{ height: '100%', width: '100%', margin: '5px' }}
      ref={mapRef}
      whenCreated={mapInstance => {
        mapRef.current = mapInstance;
      }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {polygonCoordinatesUp.map((polygon, index) => (
        <Polygon
          key={index}
          pathOptions={{ color: 'green' }}
          positions={polygon.coordinates}
          eventHandlers={{
            click: () => navigateToSatView(polygon.fieldInfo),
            mouseover: (e) => {
              e.target.openPopup();
            },
            mouseout: (e) => {
              e.target.closePopup();
            }
          }}
        >
          <Popup>
            <div><strong>Field {polygon.fieldInfo}</strong></div>
          </Popup>
        </Polygon>
      ))}
      <SetViewToBounds polygonCoordinatesUp={polygonCoordinatesUp} />
    </MapContainer>
  );
}

export default MapViewMulti;
