import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Header from '../header';
import Sidebar from './mobileSideBarUpdate';
import MainM from './mainM';
import Main from '../main';


const MobileSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Header toggleSidebar={toggleSidebar} isOpen={isOpen} />
      
    

    <div className="general_content_mobil_withoutSideBar">
    <div></div>
    
    {/*<Sidebar />*/}
    <Main />
    <div></div>
    </div>
    </div>
  );
};

export default MobileSidebar;
