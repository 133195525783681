import React, { useState, useEffect } from 'react';
import {Link, useParams} from 'react-router-dom'
import {getFields} from '../services/field_services';

function FetchData() {

  

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(()=>{
    setLoading(true);
    const getData = async () => {
        await getFields().then(data => {
        setLoading(false);
        setData(data);
    } )
    }
    getData();
},  []   )


if (error) return <h1>Error</h1>
if (loading) return <h1>Loading...</h1>

  return (
    <div>
        { data && data.map(data => {   // { means if and if data and then make after && part
            return <Link key={data.id} to ={`/weather/${data.id}`}> 
                    <p >{data.name}</p>
                </Link>
        } )}
    </div>
  );
}

export default FetchData;



/*


import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'

function FetchData() {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(()=>{
    setLoading(true);
    const getData = async () => {
        await fetch ('http://127.0.0.1:8000/api/costumer/')
        .then(resp => resp.json())
        .then(data => {
            setData(data);
            setLoading(false);
            //console.log(data);
        }).catch(e => {
          setError(true);
          setLoading(false);
        })
    }
    getData();
},  []   )


if (error) return <h1>Error</h1>
if (loading) return <h1>Loading...</h1>

  return (
    <div>
        { data && data.map(data => {   // { means if and if data and then make after && part
            return <p key={data.id}>{data.first_name}</p>
        } )}
    </div>
  );
}

export default FetchData;

*/