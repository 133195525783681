import React, { useState, useEffect, useRef } from 'react';
import annonese_home from '../../assets/annonese_weatherstation.png';
import thermometer from '../../assets/thermometer.png';
import rain from '../../assets/rain.png';
import wind from '../../assets/wind.png';
import wind_arrow from '../../assets/wind_arrow.png';
//import { Line } from "react-chartjs-2";
import {Link , useParams} from 'react-router-dom'
import {useFetchField} from '../../hooks/fetch_field';
//import { DateTime } from 'luxon';
import { getFarms, getFields } from '../../services/field_services';
import FarmPageMap from '../../components/farmPageMap';
import { GoogleMap, LoadScript, Polygon, LatLngLiteral  } from '@react-google-maps/api';
import {MarkerF} from '@react-google-maps/api'

import {useAuth} from "../../hooks/useAuth";
import { useToken } from '../../components/useToken';
import MediaQuery from 'react-responsive';

import { useFarmData } from '../../hooks/fetch_apiFarm';


import { useWeatherStationData } from '../../hooks/fetch_apiDevices';
import MapWithProps from '../../elements/mapWithProps';
import { useFetchFieldCoordinatesMulti } from '../../hooks/fetch_FieldCoordinates_Multi';
import MapViewMulti from '../../components/leaFlatFieldMapMulti';
import { useNavigate } from 'react-router-dom';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  //###################################################################################################

  
  const center = {
    lat: 44.857771,
    lng: 7.895330
  };


function FarmPage() {
  const token = useToken()
    const{ id  } = useParams();
    const { data, loading, error } = useFarmData(token, id); // Use the custom hook
    console.log("here data", data)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    //console.log("here data useFarmData", data)

    const { dataFieldCoordinatesMulti, loadingCoor, errorCoor } = useFetchFieldCoordinatesMulti(token, id); // Use the custom hook
  // State to store the final polygon coordinates
  const [polygonCoordinatesUp, setPolygonCoordinatesUp] = useState([]);

  useEffect(() => {
    console.log("only coors initial", dataFieldCoordinatesMulti);

    // Check if data is available and process it
    if (dataFieldCoordinatesMulti && dataFieldCoordinatesMulti.length > 0) {
      const filteredData = dataFieldCoordinatesMulti.filter(fieldData =>
        
        fieldData.farm_info === data[0].name
        
      );
      //console.log("data name ",data[0].name)
      console.log("Filtered Data:", filteredData);
      const cornersDataOld = filteredData.map(fieldData =>
        fieldData.coordinateInfo?.[0]?.corners ?? [],
       
        
      );

      const cornersData = filteredData.map(fieldData => ({
        coordinates: fieldData.coordinateInfo?.[0]?.corners ?? [],
        fieldInfo: fieldData.field_name // Capture the field_info here  
        //it was field_info to capture field id to show on map
      }));
     

      console.log("only coors corners", cornersData);

      if (cornersData.length > 0) {
        const newPolygonCoordinates = cornersData.map(polygonData => ({
          coordinates: polygonData.coordinates.map(corner => {
            const key = Object.keys(corner)[0];
            return corner[key];
          }),
          fieldInfo: polygonData.fieldInfo
        }));
        setPolygonCoordinatesUp(newPolygonCoordinates);
      }
    }
  }, [dataFieldCoordinatesMulti]);
// This will re-run the effect when dataFieldCoordinatesMulti changes

  console.log("only coors", polygonCoordinatesUp);
    
 

    //const { center, data, loading, error } = useWeatherStationData(id, token); // Use the custom hook

    useEffect(() => {
      // Function to update isMobile when the window is resized
      function handleResize() {
        setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
      }
    
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
    
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const containerStyle = {
     // width: '70vw',
      width: isMobile ? 'auto' : '70vw',
    height: isMobile ? '30vh' : '60vh'
      //width: 'auto',
      //height: '100vh'
      //height: isMobile ? '30vh' : '60vh',
    };

   // const [data, setData] = useState(null);
//const [loading, setLoading] = useState(false);
//const [error, setError] = useState(false);
   // const[data,loading, error] = useFetchField(id)
    const[fields, setFields] = useState(null);



  /*useEffect(()=>{
    setFields(data);
},  [data]   ) */


const [items, setItems] = useState([]);

useEffect(() => {
  const items = JSON.parse(localStorage.getItem('nabu-user'));
  if (items) {
   setItems(items);
  }
}, []);



const [filteredData, setFilteredData] = useState([]);
//setFilteredData(data && data.find(item => item.id === id));
const [indexImage, setındexImage] = useState("")
/*useEffect(()=>{
    setLoading(true);
    
    
    const getData = async () => {
        await getFarms().then(data => {
        setLoading(false);
        setData(data);
        if (data) {
            //setFilteredData(data.find(item => item.farm_info === id));
            setFilteredData(data.filter(item => item.id === parseInt(id)));
          }
        
    } )
    }
    getData();
},  [id]   ) */
/*
useEffect(() => {
  async function fetchData() {
    setLoading(true);
    try {
      const headers = new Headers({
        'Authorization': `Token ${token}`
      });

      const response = await fetch(`https://nabuapplication.com/api/farms/`, { headers });
      const data = await response.json();
      setData(data);
      if (data) {
        //setFilteredData(data.find(item => item.farm_info === id));
        setFilteredData(data.filter(item => item.id === parseInt(id)));
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }

  if (token) {
    fetchData();
  }
}, [token]); */



const center2 = {
  lat: 44.861964788375076,   //removed
  lng: 7.511081498647133  
};
const polygonPath2 = [
  { lat: 44.857771, lng: 7.906330 },
  { lat: 44.857771, lng: 7.896330 },
  { lat: 44.868771, lng: 7.905330 },
  { lat: 44.868771, lng: 7.895330 }
];


const position2 = { lat: 44.861964788375076, lng:7.511081498647133 };

const navigate = useNavigate();
const navigateToSatView = (id) => {
  navigate(`/home/sattview/${id}`);
};



if (error) return <h1>Error</h1>
//if (loading) return <h1>Loading...</h1>


    return (
      <div>
       
        <MediaQuery minWidth={800}>
          { data  ? data.map((item, index) => (
            <div>
              <div key={index} className='titleFarmPage'>
                Your {item.name} Farm
              </div>
               
              <div className='mapFieldPage'>
              <MapViewMulti  containerStyle={containerStyle} polygonCoordinatesUp={polygonCoordinatesUp}  onClick={() => navigateToSatView(id)} navigateToSatView={navigateToSatView} ></MapViewMulti>
              {/*
                {/*<MapWeatherStation center={center2} position={position2} polygonPath={polygonPath2} /> *-/}
                <MapWithProps isMobile={!isMobile} containerStyle={containerStyle} center={data[0].coordinates}  
                markerPosition={center2}
                markerTitle="Your Farm Position" />*/}
              </div>  
              

              
        
          </div>
      )) :  'No data available'}
      </MediaQuery>
      
      <MediaQuery maxWidth={799}>
        {/* Render content for screens smaller than 800px */}
        <MapWithProps isMobile={isMobile} containerStyle={containerStyle} center={center}  
                markerPosition={center}
                markerTitle="Your Farm Position" />
              
        <p>Our mobile version still in process</p>
      
      </MediaQuery>

      
      </div>
      
    

    );

    
  }
  
  export default FarmPage;