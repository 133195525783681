import { useState } from 'react';
//import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useToken } from '.././useToken';
import React, {  useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import MediaQuery from 'react-responsive';


function SattImagesLanding() {
    const token = useToken()
    const [deviceData, setDeviceData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const navigate = useNavigate(); // <-- added this line
  
    useEffect(() => {
      async function fetchData() {
        setLoading(true);
        try {
          const headers = new Headers({
            'Authorization': `Token ${token}`
          });
    
          const response = await fetch(`https://nabuapplication.com/api/fields/`, { headers });
          const data = await response.json();
          setDeviceData(data);
        } catch (e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      }
    
      if (token) {
        fetchData();
      }
    }, [token]);
  
    const [typeSelection, setTypeSelection] = useState('');

    //const inverterDevices = deviceData?.filter(device => device.device_type === 'inverter'); // <-- changed this line to handle null deviceData
  
    const handleSelectionChange = evt => {
      const deviceId = evt.target.value;
      navigate(`/home/sattview/${deviceId}`); // <-- added this line to navigate to the new page
      setTypeSelection(deviceId);
    };
  
    return (
      <>  <MediaQuery minWidth={800}>
        <div className='WStitle'>Select Your Field</div> 
        <br></br>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select the Field</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={typeSelection}
            label="Select the Available Data Duration"
            onChange={handleSelectionChange}
          >

            {deviceData?.map(device => (
              <MenuItem key={device.id} value={device.id}>
                {device.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </MediaQuery>
        <MediaQuery maxWidth={799}>
          <div className='fieldSelectMobil'>
        <div className='WStitleMobil'>Select Your Field</div> 
        <br></br>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select the Field</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={typeSelection}
            label="Select the Available Data Duration"
            onChange={handleSelectionChange}
          >

            {deviceData?.map(device => (
              <MenuItem key={device.id} value={device.id}>
                {device.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
    </MediaQuery>
      </>
    );
  }
  
export default SattImagesLanding;