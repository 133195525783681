import React, { useState, useEffect } from 'react';
/* global google */
import { SymbolPath } from '@react-google-maps/api';

import {Link , useParams} from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem';
import thermometer from '../../assets/thermometer.png';
import rain from '../../assets/rain.png';
import wind from '../../assets/wind.png';
import elc_motor from '../../assets/motor.png';
import time from '../../assets/time.png';
import status from '../../assets/pompstatus.png';
import external from '../../assets/external.png';
import wind_arrow from '../../assets/wind_arrow.png';
import { styled, alpha } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ScheduleForm from './picker';
import Modal from './settings'; // Assuming you have a Modal component

import HeatPumpOutlinedIcon from '@mui/icons-material/HeatPumpOutlined';
import ElectricMeterOutlinedIcon from '@mui/icons-material/ElectricMeterOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import CommitOutlinedIcon from '@mui/icons-material/CommitOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import SignalWifiStatusbarNullOutlinedIcon from '@mui/icons-material/SignalWifiStatusbarNullOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import OpacityOutlinedIcon from '@mui/icons-material/OpacityOutlined';
import GasMeterOutlinedIcon from '@mui/icons-material/GasMeterOutlined';
import AirOutlinedIcon from '@mui/icons-material/AirOutlined';
import WaterfallChartOutlinedIcon from '@mui/icons-material/WaterfallChartOutlined';
import WavesOutlinedIcon from '@mui/icons-material/WavesOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Decimal from 'decimal.js';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import { GoogleMap, LoadScript, Polygon, LatLngLiteral, OverlayView  } from '@react-google-maps/api';
import {MarkerF} from '@react-google-maps/api'


import { useToken } from '../../components/useToken';
import MediaQuery from 'react-responsive';

import CircularProgress from '@mui/material/CircularProgress';


import useFetchDeviceInverterPage from '../../hooks/fetch_apiDeviceInverterPage';

import MapWithProps from '../../elements/mapWithProps';




import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  

  

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.35),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.45),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    marginBottom: 5,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));


  const containerStyle = {
    width: '40vw',
    height: '50vh'
  };

  
  const center = {
    lat: 44.857771,
    lng: 7.895330
  };

  const polygonPath = [
    { lat: 44.857771, lng: 7.906330 },
    { lat: 44.857771, lng: 7.906330 },
    { lat: 44.868771, lng: 7.895330 },
    { lat: 44.868771, lng: 7.895330 }
  ];
  
  
  const position = { lat: 44.857771, lng:7.895330 };
  

  const MapWeatherStation = ({ center, position, polygonPath,colorParameter }) => {
    const adjustedPosition = {
      lat: position.lat + 0.00090, // This value controls the shift amount. Adjust as needed.
      lng: position.lng
    };
  
    // Create a label that is a circle using Unicode and set its color.
    const circleLabel = {
      text: '⬤',
      color: colorParameter,
      fontSize: '30px',
      fontWeight: 'bold'
    };
    const titleLabel = {
      text: 'Inverter Position',
      color: 'white', // or any color you prefer
      fontSize: '30px',
      fontWeight: 'bold',
      yOffset: -90 // offset the label upward
    };
  
    return (
     
        <LoadScript googleMapsApiKey="AIzaSyC-5-orAsj6wLs_cNDoUvAR7VmDVk6U6w0">
                  <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={16}
                mapTypeId={'satellite'}
            >  {/*
                <MarkerF 
                    position={position} 
                    label={circleLabel}
                    title="This is the inverter position"  // Title added here
                />   */}
                <MarkerF 
                    position={adjustedPosition}
                    label={titleLabel}
                    
                />
                <Polygon
                    path={polygonPath}
                    options={{
                      clickable: true,
                      zIndex: 1,
                      strokeColor: "#FF0000",
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor: "#FF0000",
                      fillOpacity: 0.35,
                    }}
                />
            </GoogleMap>
        </LoadScript>
    );
};

function InverterMain() {
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(false);
    const{ id } = useParams();
    const token = useToken()
    //console.log(token)

    const [number, setNumber] = useState(2.5);  //2.5
    const [numberAssigned, setNumberAssigned] = useState(2.5);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const containerStyle = {
      width: 'auto',
      //height: '60vh'
      height: isMobile ? '40vh' : '60vh',
    };
  
    /*
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    

    useEffect(() => {
        let intervalId;
        
        async function fetchData() {
          setLoading(true);
          try {
            const headers = new Headers({
              'Authorization': `Token ${token}`
            });
      
            const response = await fetch(`https://nabuapplication.com/api/devices/${id}`, { headers });
            const data = await response.json();
            setData(data);
          } catch (e) {
            setError(e);
          } finally {
            setLoading(false);
          }
        }
      
        if (token) {
          fetchData();
          intervalId = setInterval(fetchData, 10000); // Fetch data every 10 seconds
        }
      
        return () => clearInterval(intervalId); // Clear the interval on unmount
      }, [id, token]);*/
      const [loading, setLoading] = useState(false);
  //const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const initialPosition = { lat: 46.06513570699524, lng:  7.657729152090871 };    
  const [positionNew, setPositionNew] = useState(initialPosition);

  const { data, loadingHook, errorHook, positionNew: currentPosition } = useFetchDeviceInverterPage(id, token);
 

  {/* 

  useEffect(() => {
    let intervalId;

    async function fetchData() {
      setLoading(true);
      try {
        const headers = new Headers({
          'Authorization': `Token ${token}`
        });

        const response = await fetch(`https://nabuapplication.com/api/devices/${id}`, { headers });
        const data = await response.json();
        setData(data);
        setPositionNew({
          lat: data.coordinates.lat,
          lng: data.coordinates.lng
        });
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    if (token) {
      fetchData();
      //intervalId = setInterval(fetchData, 10000); // Fetch data every 10 seconds
    }

    return () => clearInterval(intervalId); // Clear the interval on unmount
  }, [id, token]);
  */}


  ////////////////////////////////////////////////////////////////////////////
  const fetchDataStatus = async () => {
    setLoadingStatusData(true);
    try {
      const headers = new Headers({
        'Authorization': `Token ${token}`
      });
  
      const response = await fetch(`https://nabuapplication.com/api/inverterstatus/`, { headers });
      const data = await response.json();
      setStatusData(data);
    } catch (e) {
      setErrorStatusData(e);
    } finally {
      setLoadingStatusData(false);
    }
  };
  

  const [statusData, setStatusData] = useState(null);
  const [loadingStatusData, setLoadingStatusData] = useState(false);
  const [errorStatusData, setErrorStatusData] = useState(null);
  useEffect(() => {
    let intervalId;



    if (token) {
      fetchDataStatus();
      //intervalId = setInterval(fetchData, 10000); // Fetch data every 10 seconds
    }
    //const interval = setInterval(fetchDataStatus, 5000);
    return () => clearInterval(intervalId); // Clear the interval on unmount
  }, [id, token]);


  useEffect(() => {
    if (statusData && statusData.length > 0) {
      const firstStatus = statusData[0];
      setChecked(firstStatus.inverter_status === true);
    }
   
  }, [statusData]);

  const handleClickRefreh = () => {
    // Handle button click
    console.log('Button clicked');
    fetchDataStatus();
  };

  //////////////////////////////////////////////////////////////////////////
  

 

  const [updateNumber, setUpdateNumber] = useState(null);

  useEffect(() => {
    
    const fetchRandomNumber = async () => {
      try {
        const headers = new Headers({
          'Authorization': `Token ${token}`
        });
        const response = await fetch(`https://nabuapplication.com/api/devices/${id}`, { headers });
        const data = await response.json();
        //const response = await fetch("https://www.random.org/integers/?num=1&min=1&max=100&col=1&base=10&format=plain&rnd=new");
        //const data = await response.text();
        //setUpdateNumber(parseInt(data));
        setUpdateNumber(data);
       
      } catch (error) {
        console.error("Error fetching random number:", error);
      }
    };

    const interval = setInterval(fetchRandomNumber, 10000);
    

    return () => {
      clearInterval(interval);
    };
  }, [id, token]);
      
    const [value, setValue] = React.useState('one');

    const handleChange = () => {
      setValue();
    };


   // const[data,loading, error] = useFetchField(id)
    const[fields, setFields] = useState(null);
    const [typeSelection, setTypeSelection] = React.useState('');


  useEffect(()=>{
    setFields(data);
},  [data]   )


const [items, setItems] = useState([]);

useEffect(() => {
  const items = JSON.parse(localStorage.getItem('nabu-user'));
  if (items) {
   setItems(items);
  }
}, []);



const [filteredData, setFilteredData] = useState([]);
//setFilteredData(data && data.find(item => item.id === id));
const [indexImage, setındexImage] = useState("")
/*useEffect(()=>{
    setLoading(true);
    
    
    const getData = async () => {
        await getFarms().then(data => {
        setLoading(false);
        setData(data);
        if (data) {
            //setFilteredData(data.find(item => item.farm_info === id));
            setFilteredData(data.filter(item => item.id === parseInt(id)));
          }
        
    } )
    }
    getData();
},  [id]   ) */

useEffect(() => {
  async function fetchData() {
    setLoading(true);
    try {
      const headers = new Headers({
        'Authorization': `Token ${token}`
      });

      const response = await fetch(`https://nabuapplication.com/api/farms/`, { headers });
      const data = await response.json();
      //setData(data);     important note ı made this comment out to achieve fetching from hooks at above almost at the begining of code
      if (data) {
        //setFilteredData(data.find(item => item.farm_info === id));
        setFilteredData(data.filter(item => item.id === parseInt(id)));
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }

  if (token) {
    fetchData();
  }
}, [token]);
const center2 = {
  lat: 44.861964788375076,
  lng: 7.511081498647133  
};
const polygonPath2 = [
  { lat: 44.857771, lng: 7.906330 },
  { lat: 44.857771, lng: 7.896330 },
  { lat: 44.868771, lng: 7.905330 },
  { lat: 44.868771, lng: 7.895330 }
];


const position2 = { lat: 44.861964788375076, lng:7.511081498647133 };




if (error) return <h1>Error</h1>
if (loading) return <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                    </Box>



const handlePostRequest = async () => {
  try {
    const valueToSend = Math.round(number); // Multiply the number by 10
    // Make a POST request to the desired URL
    const response = await fetch('https://nabuapplication.com/api/trigger-lambda/', {
      method: 'POST',
      // Add any required headers or data to the request
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        
        device_id:data.device_ıd,
        item_id: 0,
        timer:false, 
        //"value":0
      
        value: checked ? 0: 1, //'off' : 'on', // Include the switch status in the request body
        pre_value: numberAssigned,
        //action: checked ? 'smth1' : 'smth2', // Include the switch status in the request body
        // Add any data to the request body if needed add some change here
      }),
    });

    // Handle the response if necessary
    // For example, check the response status
    if (response.ok) {
      // Request was successful
      // Do something
    } else {
      // Request failed
      // Handle the error
    }
  } catch (error) {
    // Handle any network or other errors
  }
};



const handlePostRequestPressure = async () => {
  try {
    const valueToSend = Math.round(number * 10); // Multiply the number by 10
    // Make a POST request to the desired URL
    const response = await fetch('https://nabuapplication.com/api/trigger-lambda/', {
      method: 'POST',
      // Add any required headers or data to the request
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        
        device_id:data.device_ıd,
        item_id: 100 ,
        timer:true, 
        //"value":0
      
        value: valueToSend,
        pre_value: valueToSend,
        //action: checked ? 'smth1' : 'smth2', // Include the switch status in the request body
        // Add any data to the request body if needed add some change here
      }),
    });

    // Handle the response if necessary
    // For example, check the response status
    if (response.ok) {
      // Request was successful
      // Do something
    } else {
      // Request failed
      // Handle the error
    }
  } catch (error) {
    // Handle any network or other errors
  }
};

const handlePostRequestForceUpdate = async () => {
  try {
    const valueToSend = Math.round(number * 10); // Multiply the number by 10
    // Make a POST request to the desired URL
    const response = await fetch('https://nabuapplication.com/api/trigger-lambda/', {
      method: 'POST',
      // Add any required headers or data to the request
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        
        device_id:data.device_ıd,
        item_id: 997 ,
        timer:false, 
        //"value":0
      
        value:1,
        pre_value: valueToSend,
        //action: checked ? 'smth1' : 'smth2', // Include the switch status in the request body
        // Add any data to the request body if needed add some change here
      }),
    });

    // Handle the response if necessary
    // For example, check the response status
    if (response.ok) {
      // Request was successful
      // Do something
    } else {
      // Request failed
      // Handle the error
    }
  } catch (error) {
    // Handle any network or other errors
  }
};

const handleChangeOnOff = (event) => {
  setChecked(event.target.checked);
  // Call your function or perform any desired action here
  handlePostRequest();
  setTimeout(() => {
    handlePostRequestForceUpdate();
  }, 5000);
  
  /*if (event.target.checked) {
    handlePostRequest();
  }
  else{
    handlePostRequest();
  }*/

};



const increaseNumber = () => {
  setNumber(number + 0.1);  //0.5
};

const decreaseNumber = () => {
  setNumber(number - 0.1);   //0.5
};
const sendPressure = () => {
  setNumberAssigned(number);   // there was no div 10
  handlePostRequestPressure();

};


const handleSettings = () => {
  setShowModal(true);
};

const handleCloseModal = () => {
  setShowModal(false);
};

return (
    
    <div className='WSmain'>
      <MediaQuery minWidth={800}>
        {fields &&
            <div className='titleSep'>
              <React.Fragment >
                <div className='WStitle'>Manage Your Irrigation</div> 
              </React.Fragment>
              <div className='iconWrap' onClick={handleSettings}>
                <SettingsOutlinedIcon></SettingsOutlinedIcon>
              </div>
              {showModal && (
        <Modal onClose={handleCloseModal}>
          {/* Content of the modal, such as the settings form */}
          {/* Add your settings form components here */}
        </Modal>
      )}
            </div>
        }
        {fields &&
  
                            <React.Fragment>
        <div className='map_data_inverter'>
            {/*<img className = "mapclass" src={annonese_home}/>*/}
            <div className = "mapclass">
            <br></br>  
            <br></br>
            <MapWeatherStation center={currentPosition} position={currentPosition} polygonPath={polygonPath2}/>

            </div>
            
            <div className= "inverter_map_right">
              <div>{/*Pump*/}</div> 
              <div></div>
            <div className = "detail_boxe_inverter">
                    
                    <div className='inverter_inner_item'>
                        <div className='inverter_icon'>
                          <div className="centeredIcon">
                            <HeatPumpOutlinedIcon className= "muicons" sx={{ fontSize: 70 }} color="white" />
                            </div>
                        </div>
                        <div></div>
                        <div className='inverter_text'>
                            <h1>Function</h1>
                            <div>
                            
                              <FormControlLabel control={<Switch checked={checked} onChange={handleChangeOnOff} size="medium"/>} label="" />
                           
                            </div>
                            
                        </div>
                        
                    </div>
                    <div></div>
                    <div className='inverter_inner_item'>
                        <div className='inverter_icon'>
                        <div className="centeredIcon">
                            <ElectricMeterOutlinedIcon className= "muicons" sx={{ fontSize: 70 }} />
                          </div>
                        </div>
                        <div></div>
                        <div className='inverter_text'>
                            <h1>Voltage</h1>
                            {<h1>1</h1>}
                            {/*updateNumber !== null ? (
                                <h2>{updateNumber.inverter_data[0].frequency}</h2>
                              ) : (
                                <p>Loading...</p>
                              )*/}
                        </div>
                        
                    </div>
                    <div></div>
                    <div className='inverter_inner_item'>
                        <div className='inverter_icon'>
                        <div className="centeredIcon">
                            <AutorenewOutlinedIcon className= "muicons" sx={{ fontSize: 70 }} />
                            </div>
                        </div>
                        <div></div>
                        <div className='inverter_text'>
                            <h1>Frequency</h1>
                            {updateNumber && updateNumber.inverter_data ? (
                                <h1>{updateNumber.inverter_data[0].frequency} Hz</h1>)
                                : (
                                  <Box sx={{ display: 'flex' }}>
                                  {/*<p>Loading...</p>*/}
                                  <CircularProgress />
                                </Box>
                                )}
                        </div>
                        
                    </div>
                
        
            </div>  
                <div></div>
                <div className = "detail_boxe_inverter">
                    
                        <div className='inverter_inner_item'>
                            <div className='inverter_icon'>
                            <div className="centeredIcon">
                                <CommitOutlinedIcon className= "muicons" sx={{ fontSize: 70 }}/>
                                </div>
                            </div>
                            <div></div>
                            <div className='inverter_text'>
                                <h1>Current</h1>
                             
                                {updateNumber && updateNumber.inverter_data && (
                                <h1>{updateNumber.inverter_data[0].current} A</h1>)}
                                
                            </div>
                            
                        </div>
                        <div></div>
                        <div className='inverter_inner_item'>
                            <div className='inverter_icon'>
                            <div className="centeredIcon">
                                <BoltOutlinedIcon className= "muicons" sx={{ fontSize: 70 }}/>
                                </div>
                            </div>
                            <div></div>
                            <div className='inverter_text'>
                                <h1>Power</h1>
                                {updateNumber && updateNumber.inverter_data && (
                                <h1>{updateNumber.inverter_data[0].power} kW</h1>)}
                            </div>
                            
                        </div>
                        <div></div>
                        <div className='inverter_inner_item'>
                            <div className='inverter_icon'>
                            <div className="centeredIcon">
                                <SignalWifiStatusbarNullOutlinedIcon className= "muicons" sx={{ fontSize: 70 }}/>
                                </div>
                            </div>
                            <div></div>
                            <div className='inverter_text'>
                                <h1>Status</h1>
                                
                                {updateNumber && updateNumber.inverter_data && (
                                  <h1 style={{ color: updateNumber.inverter_data[0].frequency > 0 ? 'green' : 'red' }}>
                                    {updateNumber.inverter_data[0].frequency > 0 ? 'Pump On' : 'Pump Off'}
                                  </h1>
                                )}
                                {/*data && data.inverter_data && (
                                <h1>{data.inverter_data[0].frequency} Hz</h1>)*/}
                            </div>
                            
                        </div>
                    
            
                </div>  
                <div></div> 
                <div className = "detail_boxe_inverter">
                    
                        <div className='inverter_inner_item'>
                            <div className='inverter_icon'>
                            <div className="centeredIcon">
                                <TimerOutlinedIcon className= "muicons" sx={{ fontSize: 70 }}/>
                                </div>
                            </div>
                            <div></div>
                            <div className='inverter_text'>
                                <h1>Hours</h1>
                                <h1>0 A</h1>
                            </div>
                            
                        </div>
                        <div></div>
                        <div className='inverter_inner_item'>
                            <div className='inverter_icon'>
                            <div className="centeredIcon">
                                <OpacityOutlinedIcon className= "muicons" sx={{ fontSize: 70 }}/>
                                </div>
                            </div>
                            <div></div>
                            <div className='inverter_text'>
                                <h1>Pressure</h1>
                                {updateNumber && updateNumber.inverter_data && (
                                <h1>{updateNumber.inverter_data[0].pressure} bar</h1>)}
                            </div>
                            
                        </div>
                        <div></div>
                        <div className='inverter_inner_item'>
                            <div className='inverter_icon'>
                            <div className="centeredIcon">
                                <GasMeterOutlinedIcon className= "muicons" sx={{ fontSize: 70 }}/>
                                </div>
                            </div>
                            <div></div>
                            <div className='inverter_textSetPoint'>
                                <h1>Setpoint</h1>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                  <h1>{number.toFixed(1)}</h1>
                                  <IconButton aria-label="delete" size="large" onClick={sendPressure}>
                                  <CheckCircleOutlineOutlinedIcon fontSize="inherit" />
                                </IconButton>
                                </Stack>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                <IconButton aria-label="delete" size="small" onClick={decreaseNumber}>
                                  <RemoveCircleOutlineOutlinedIcon fontSize="inherit" />
                                </IconButton>
                                <IconButton aria-label="delete" size="small" onClick={increaseNumber}>
                                  <ControlPointOutlinedIcon fontSize="inherit" />
                                </IconButton>
                                </Stack>
                                
                            </div>
                            
                        </div>
                    
            
                </div>    
                <div></div> 
                <div className = "detail_boxe_inverter">
                    
                        <div className='inverter_inner_item'>
                            <div className='inverter_icon'>
                            <div className="centeredIcon">
                                <AirOutlinedIcon className= "muicons" sx={{ fontSize: 70 }}/>
                                </div>
                            </div>
                            <div></div>
                            <div className='inverter_text'>
                                <h1>Flow</h1>
                                <h1>0 A</h1>
                            </div>
                            
                        </div>
                        <div></div>
                        <div className='inverter_inner_item'>
                            <div className='inverter_icon'>
                            <div className="centeredIcon">
                                <WaterfallChartOutlinedIcon className= "muicons" sx={{ fontSize: 70 }}/>
                                </div>
                            </div>
                            <div></div>
                            <div className='inverter_text'>
                                <h1>WM</h1>
                                <h1>On</h1>
                            </div>
                            
                        </div>
                        <div></div>
                        <div className='inverter_inner_item'>
                            <div className='inverter_icon'>
                            <div className="centeredIcon">
                                <WavesOutlinedIcon className= "muicons" sx={{ fontSize: 70 }}/>
                                </div>
                            </div>
                            <div></div>
                            <div className='inverter_text'>
                                <h1>Level</h1>
                                <h1>On</h1>
                            </div>
                            
                        </div>
                    
            
                </div>             
            </div>
               
        </div>
        </React.Fragment>
    }
    <div className='InverterNot'>
        <div className='notInverter'>
        <br></br>
        <div className='refreshButtonDiv'>
          <div className='WStitle'>Notifications</div>
          <div className='iconWrap' onClick={handleClickRefreh}>
            <CachedOutlinedIcon></CachedOutlinedIcon>
          </div>
          
        </div>
        
        
       
        
  
       
        <div className='InverterNotUpdate'>
            
        <div>
          {loadingStatusData ? (
            
            <Box sx={{ display: 'flex' }}>
               {/*<p>Loading...</p>*/}
              <CircularProgress />
            </Box>
          ) : errorStatusData ? (
            <p>Error: {error.message}</p>
          ) : statusData ? (
            <div style={{ height: '320px', overflowY: 'auto' }}>
              {statusData.map((dataItem) => (
                <div
                  key={dataItem.id}
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '10px',
                    marginBottom: '10px',
                    position: 'relative',
                  }}
                >
                  <p
                    style={{
                      color: dataItem.inverter_status ? 'green' : 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    Inverter Status: {dataItem.inverter_status ? 'On' : 'Off'}
                  </p>
                  <p>Desired Pressure: {dataItem.desired_pressure}</p>
                  <p
                    style={{
                      position: 'absolute',
                      bottom: '5px',
                      right: '5px',
                      fontSize: '12px',
                      color: '#888',
                    }}
                  >
                    Timestamp: {new Date(dataItem.timestamp).toLocaleString()}
                  </p>
                </div>
              ))}
            </div>
          ) : null}
        </div>

        </div>

        {/*<div className='notifications'>Notifications
            
            </div>
  <Link to={'/'}>Back</Link>*/}
    </div>
    <div className=''></div>
    <div className='notInverter'>
        <Box sx={{ width: '100%' }}>
        <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
        >
            <Tab
            value="one"
            label="Plan A"
            wrapped
            />
            <Tab value="two" label="Plan B" />
            <Tab value="three" label="Plan C" />
            <Tab value="three" label="Plan D" />
        </Tabs>
        </Box>
        <ScheduleForm></ScheduleForm>
    </div>
    </div>
    <div className='WStables'>
        {/*<br></br>
        <div className='WStitle'>Select Your Inverter</div>
        <br></br>
  
        <br></br>
        <Search>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select the Available Data Duration</InputLabel>
                    <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={typeSelection}
                        label="Select the Available Data Duration"
                        onChange={evt => setTypeSelection(evt.target.value)}
                        >
                        <MenuItem value="">
                            <em>None</em>
            
                        </MenuItem>
                        <MenuItem value={10}>Last 12 Hours</MenuItem>
                        <MenuItem value={20}>Last 1 Day </MenuItem>
                        <MenuItem value={30}>Last 1 Week</MenuItem>
                        <MenuItem value={40}>Last 2 Week</MenuItem>
                        
                        </Select>
                </FormControl>
                
    
            </Search>
            <br></br> */}
        {/*<div className='notifications'>Notifications
            
            </div>
  <Link to={'/'}>Back</Link>*/}
        
    </div>
    </MediaQuery>
    <MediaQuery maxWidth={799}>

    {fields &&
                    <React.Fragment>
                        <div className='WStitleMobil'>{fields.name} Inverter</div>
                    </React.Fragment>
                }
                {fields &&


                    <div className='map_data_mobile'>
                        {/*<img className = "mapclass" src={annonese_home}/>*/}

                        {/*<MapWeatherStationMobil center={center} ></MapWeatherStationMobil>*/}

                        <MapWithProps isMobile={isMobile} containerStyle={containerStyle} center={center}
                        markerPosition={center}
                        markerTitle="Weather Station Position" />

                    </div>
                    
                    

                }
    {/* Render content for screens smaller than 800px */}
    <br></br>
    <div className= "inverter_map_right_mobile">
              
              <div></div>
            <div className = "detail_boxe_inverterM">
                    
                    <div className='inverter_inner_item'>
                        <div className='inverter_icon'>
                          <div className="centeredIconMobile">
                            <HeatPumpOutlinedIcon className= "muicons" sx={{ fontSize: 50 }} color="white" />
                            </div>
                        </div>
                        <div></div>
                        <div className='inverter_text'>
                            <h1>Function</h1>
                            <div>
                            
                              <FormControlLabel control={<Switch checked={checked} onChange={handleChangeOnOff} size="medium"/>} label="" />
                           
                            </div>
                            
                        </div>
                        
                    </div>
                    
       
                    <div></div>
                    <div className='inverter_inner_item'>
                        <div className='inverter_icon'>
                        <div className="centeredIconMobile">
                            <AutorenewOutlinedIcon className= "muicons" sx={{ fontSize: 50 }} />
                            </div>
                        </div>
                        <div></div>
                        <div className='inverter_text'>
                            <h1>Frequency</h1>
                            {updateNumber && updateNumber.inverter_data ? (
                                <h1>{updateNumber.inverter_data[0].frequency} Hz</h1>)
                                : (
                                  <Box sx={{ display: 'flex' }}>
                                  {/*<p>Loading...</p>*/}
                                  <CircularProgress />
                                </Box>
                                )}
                        </div>
                        
                    </div>
                
        
            </div>  
                <div></div>
                <div className = "detail_boxe_inverterM">
                  
                        <div className='inverter_inner_item'>
                            <div className='inverter_icon'>
                            <div className="centeredIconMobile">
                                <BoltOutlinedIcon className= "muicons" sx={{ fontSize: 50 }}/>
                                </div>
                            </div>
                            <div></div>
                            <div className='inverter_text'>
                                <h1>Power</h1>
                                {updateNumber && updateNumber.inverter_data && (
                                <h1>{updateNumber.inverter_data[0].power} kW</h1>)}
                            </div>
                            
                        </div>
                        <div></div>
                        <div className='inverter_inner_item'>
                            <div className='inverter_icon'>
                            <div className="centeredIconMobile">
                                <SignalWifiStatusbarNullOutlinedIcon className= "muicons" sx={{ fontSize: 50 }}/>
                                </div>
                            </div>
                            <div></div>
                            <div className='inverter_text'>
                                <h1>Status</h1>
                                
                                {updateNumber && updateNumber.inverter_data && (
                                  <h1 style={{ color: updateNumber.inverter_data[0].frequency > 0 ? 'green' : 'red' }}>
                                    {updateNumber.inverter_data[0].frequency > 0 ? 'Pump On' : 'Pump Off'}
                                  </h1>
                                )}
                                {/*data && data.inverter_data && (
                                <h1>{data.inverter_data[0].frequency} Hz</h1>)*/}
                            </div>
                            
                        </div>
                    
            
                </div>  
                <div></div> 
                <div className = "detail_boxe_inverterM">
                    
                     
                        
                        <div className='inverter_inner_item'>
                            <div className='inverter_icon'>
                            <div className="centeredIconMobile">
                                <OpacityOutlinedIcon className= "muicons" sx={{ fontSize: 50 }}/>
                                </div>
                            </div>
                            <div></div>
                            <div className='inverter_text'>
                                <h1>Pressure</h1>
                                {updateNumber && updateNumber.inverter_data && (
                                <h1>{updateNumber.inverter_data[0].pressure} bar</h1>)}
                            </div>
                            
                        </div>
                        <div></div>
                        <div className='inverter_inner_item'>
                            <div className='inverter_icon'>
                            <div className="centeredIconMobile">
                                <GasMeterOutlinedIcon className= "muicons" sx={{ fontSize: 50 }}/>
                                </div>
                            </div>
                            <div></div>
                            <div className='inverter_textSetPoint'>
                                <h1>Setpoint</h1>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                  <h1>{number.toFixed(1)}</h1>
                                  <IconButton aria-label="delete" size="large" onClick={sendPressure}>
                                  <CheckCircleOutlineOutlinedIcon fontSize="inherit" />
                                </IconButton>
                                </Stack>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                <IconButton aria-label="delete" size="small" onClick={decreaseNumber}>
                                  <RemoveCircleOutlineOutlinedIcon fontSize="inherit" />
                                </IconButton>
                                <IconButton aria-label="delete" size="small" onClick={increaseNumber}>
                                  <ControlPointOutlinedIcon fontSize="inherit" />
                                </IconButton>
                                </Stack>
                                
                            </div>
                            
                        </div>
                    
            
                </div>    
                <div></div> 
                 
            </div>
    <br></br>
    <div className=''>
        <div className='notInverter'>
    <div className='refreshButtonDiv'>
      
          <div className='WStitleMobilInverterPage'>Notifications</div>
          <div className='iconWrap' onClick={handleClickRefreh}>
            <CachedOutlinedIcon></CachedOutlinedIcon>
          </div>
          
        </div>
        <div className='InverterNotUpdate'>
            
        <div>
          {loadingStatusData ? (
            
            <Box sx={{ display: 'flex' }}>
               {/*<p>Loading...</p>*/}
              <CircularProgress />
            </Box>
          ) : errorStatusData ? (
            <p>Error: {error.message}</p>
          ) : statusData ? (
            <div style={{ height: '320px', overflowY: 'auto' }}>
              {statusData.map((dataItem) => (
                <div
                  key={dataItem.id}
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '10px',
                    marginBottom: '10px',
                    position: 'relative',
                  }}
                >
                  <p
                    style={{
                      color: dataItem.inverter_status ? 'green' : 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    Inverter Status: {dataItem.inverter_status ? 'On' : 'Off'}
                  </p>
                  <p>Desired Pressure: {dataItem.desired_pressure}</p>
                  <p
                    style={{
                      position: 'absolute',
                      bottom: '5px',
                      right: '5px',
                      fontSize: '12px',
                      color: '#888',
                    }}
                  >
                    Timestamp: {new Date(dataItem.timestamp).toLocaleString()}
                  </p>
                </div>
              ))}
            </div>
          ) : null}
        </div>

        </div>
        </div>
        </div>

    <EngineeringRoundedIcon></EngineeringRoundedIcon>
  </MediaQuery>
    </div>

    

);

    
  }
  
  function RelevantComponent({ data }) {
    // Render the relevant component using the retrieved data
    return (
      <div>
        {data && data.inverter_data && (
                                  <h1>{data.inverter_data[0].pressure} Hz</h1>)}
        {/* Render the rest of the component */}
      </div>
    );
  }

export default InverterMain;


