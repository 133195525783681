
import './App.css';
import React from 'react';

import Header from './layoutElements/header';
import SideBar from './layoutElements/sidebar';
import Main from './layoutElements/main';
//import MySidebar from './assets/secondsidebar';
import Footer from './layoutElements/footer';
import {BrowserRouter as Router} from 'react-router-dom'
import Auth from './components/auth';
//import HomePage from './components/homePage';
import { Routes, Route } from 'react-router-dom';
//import HomePage from './components/homePage';
import HomePage from './pages/HomePageMain/homePage';

import {AuthProvider} from  './hooks/useAuth';
import Register from  './components/register'





function App() {
  const user = JSON.parse(localStorage.getItem('nabu-user'));

  return (
    <AuthProvider user = {user}>
      <Router>
          <Routes>
            <Route exact path ='/' element={<Auth></Auth>}></Route>
            <Route exact path ='/home/*' element={<HomePage></HomePage>}></Route>
            <Route exact path ='/register' element={<Register></Register>}></Route>
    
                
            
        

        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;





















/*import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/AccessAlarm';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const theme = createTheme();

export default function Album() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <CameraIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            Album layout
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {// Hero unit }
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Album layout
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Something short and leading about the collection below—its contents,
              the creator, etc. Make it short and sweet, but not too short so folks
              don&apos;t simply skip over it entirely.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained">Main call to action</Button>
              <Button variant="outlined">Secondary action</Button>
            </Stack>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="md">
          {// End hero unit }
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                    }}
                    image="https://source.unsplash.com/random"
                    alt="random"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Heading
                    </Typography>
                    <Typography>
                      This is a media card. You can use this section to describe the
                      content.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small">View</Button>
                    <Button size="small">Edit</Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {// Footer }
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Footer
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Something here to give the footer a purpose!
        </Typography>
        <Copyright />
      </Box>
      {///* End footer }
    </ThemeProvider>
  );
}


*/
/*
import { Grid } from '@material-ui/core';
import React from 'react';

function MyLayout() {
  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <p>This element takes up the full width on small screens and half the width on medium screens and up.</p>
      </Grid>
      <Grid item xs={12} sm={6}>
        <p>This element also takes up the full width on small screens and half the width on medium screens and up.</p>
      </Grid>
    </Grid>
  );
}

export default MyLayout;


*/




/*
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  appBar: {
    
    
  }
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  

  return (
    <div >
      <AppBar position="static" className={classes.appBar} style={{ backgroundColor: "green", borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>
        <Toolbar>
          <Grid container>
          <Grid item xs={1} style={{ backgroundColor: "white" }} />
            <Grid item xs={10}>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer('left', true)}>
                <MenuIcon />
              </IconButton>
  
            </Grid>
            <Grid item xs={1} style={{ backgroundColor: "white" }} />
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
        {sideList('left')}
      </Drawer>
      <main>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
        <p>Example content goes here</p>
      </main>
      <footer style={{ position: "fixed", bottom: 0, backgroundColor: "grey", width: "100%", display: "flex", justifyContent: "center" }}>
        <Button>Button 1</Button>
        <Button>Button 2</Button>
        <Button>Button 3</Button>
        <Button>Button 4</Button>
      </footer>
    </div>

  );

  }

*/