import React, { useState } from 'react';
import GasMeterOutlinedIcon from '@mui/icons-material/GasMeterOutlined';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const Modal = ({ onClose }) => {
  const initialState = [
    { id: 1, number: 2.5, numberAssigned: 2.5, name: "SetPoint", lowerLimit: 0.5, upperLimit: 5, multi: 10},
    { id: 2, number: 10, numberAssigned: 2.5, name: "Accelaration",lowerLimit: 0, upperLimit: 6000, multi: 10 },
    { id: 3, number: 10, numberAssigned: 2.5, name: "Decelaration",lowerLimit: 0, upperLimit: 6000,multi: 10 },
    { id: 4, number: 36, numberAssigned: 2.5, name: "freq_min" , lowerLimit: 35, upperLimit: 50,multi: 100},
    { id: 5, number: 50, numberAssigned: 2.5, name: "freq_max" ,lowerLimit: 36.5, upperLimit: 50,multi: 100},
    { id: 6, number: 25, numberAssigned: 2.5, name: "nominal current",lowerLimit: 2, upperLimit: 5,multi: 10 },
    { id: 7, number: 25, numberAssigned: 2.5, name: "pressure transmitter" ,lowerLimit: 2, upperLimit: 5,multi: 10},
    { id: 8, number: 2.3, numberAssigned: 2.5, name: "start pressure" ,lowerLimit: 0, upperLimit: 50,multi: 10},
    // Add more objects for additional boxes
  ];

  const [boxes, setBoxes] = useState(initialState);

  const increaseNumber = (itemId) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === itemId
          ? {
              ...box,
              number: Math.min(box.number + 0.1, box.upperLimit),
            }
          : box
      )
    );
  };

  const decreaseNumber = (itemId) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === itemId
          ? {
              ...box,
              number: Math.max(box.number - 0.1, box.lowerLimit),
            }
          : box
      )
    );
  };

  const sendPressure = (itemId) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === itemId ? { ...box, numberAssigned: box.number } : box
      )
    );
    handlePostRequestPressure(itemId);
  };

  const handlePostRequestPressure = async (itemId) => {
    try {
      const selectedBox = boxes.find((box) => box.id === itemId);
      if (selectedBox) {
        // Make a POST request to the desired URL
        const response = await fetch('https://nabuapplication.com/api/trigger-lambda/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            device_id: 356116354785580,
            item_id: itemId+99,
            timer: true,
            value: Math.round(selectedBox.number * selectedBox.multi),
            pre_value: Math.round(selectedBox.number * 100),
          }),
        });

        // Handle the response if necessary
        if (response.ok) {
          // Request was successful
          // Do something
        } else {
          // Request failed
          // Handle the error
        }
      }
    } catch (error) {
      // Handle any network or other errors
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-content">
          <h2>Settings</h2>
          <div className="settings-box">
            {boxes.map((box) => (
              <div className="inverter_inner_item" key={box.id}>
                <div className="inverter_icon">
                  <div className="centeredIcon">
                    <GasMeterOutlinedIcon className="muicons" sx={{ fontSize: 70 }} />
                  </div>
                </div>
                <div></div>
                <div className="inverter_textSetPoint">
                  <h1>{box.name}</h1>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <h1>{box.number.toFixed(1)}</h1>
                    <IconButton aria-label="delete" size="large" onClick={() => sendPressure(box.id)}>
                      <CheckCircleOutlineOutlinedIcon fontSize="inherit" />
                    </IconButton>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => decreaseNumber(box.id)}
                      disabled={box.number <= box.lowerLimit}
                    >
                      <RemoveCircleOutlineOutlinedIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => increaseNumber(box.id)}
                      disabled={box.number >= box.upperLimit}
                    >
                      <ControlPointOutlinedIcon fontSize="inherit" />
                    </IconButton>
                  </Stack>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Modal;