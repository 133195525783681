import React, { useState, useEffect } from 'react';
import {Link , useParams} from 'react-router-dom'

import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import HttpsIcon from '@mui/icons-material/Https';


import '../components/auth.css';

import logo from '../assets/logo_nabu_login.png';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';




import { purple } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { auth } from "../services/user_services";
import {useAuth} from "../hooks/useAuth";
import { register } from "../services/user_services";


const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: purple[500],
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#11cb5f',
      },
    },
  });

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.35),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.45),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    marginBottom: 5,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '30ch',
      },
    },
  }));
  const StyledInputBasePass = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        //width: '24.5ch',
        width: '30ch',
      },
    },
  }));
 

function AddFarm() {
 
   const{ id } = useParams();

   const url = 'http://127.0.0.1:8000/api/farms/';

   
   
   





    const [username, setUsername] = useState('');
    const [deviceID, setdeviceID] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const { authData, setAuth} = useAuth();
    const { regData, setRegData} = useAuth();
    const [userID, setUserId] = useState(null)
     const data = {
        name: username,
        user_id: authData.user.id,
      };
      const dataFieldSend = {
        name: username,
        user_id: authData.user.id,
        device_ıd: deviceID
      };
   
    //const [isLoginView, setIsLoginView] = useState(true);

    //const [token, setToken] = useCookies(['mr-token']);

    const [buttonName, setbuttonName] = useState("Register Now!")
    const [logout, setLogout] = useState("Register")

    /*useEffect(() => {
        console.log(token);
        if(token['mr-token']) window.location.href = '/';
    }, [token])  */


    const isDisabled = username.length ===0 || password.length === 0;




    const handleSubmit = async e => {
        e.preventDefault();
   
        fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
            .then(response => response.json())
            .then(result => console.log(result))
            .catch(error => console.log(error));
  
      }

      const logOut = () => {
        setAuth(null);
      }

    return (
        <div className="">
            
             
            <h1 className="font-link_robota" >Register Your New Farm </h1>
            {!regData ? 
            <div>
            <form onSubmit={handleSubmit}>
            <div>            
                <Search>
                    <SearchIconWrapper>
                        <BadgeOutlinedIcon/>
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="New Farm Name"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange= { evt => setUsername(evt.target.value)}
                        id="fieldname" 
                        value ={username}


                        
                       
                />
                
           </Search></div>
           <div>            
                <Search>
                    <SearchIconWrapper>
                        <BadgeOutlinedIcon/>
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="First Field belong to your new Farm"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange= { evt => setUsername(evt.target.value)}
                        id="fieldname" 
                        value ={username}


                        
                       
                />
                
           </Search></div>


           <div>
           <Search>
                    <SearchIconWrapper>
                        <DeviceHubOutlinedIcon/>
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Device ID belong to selected Field"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange= { evt => setdeviceID(evt.target.value)}
                        id="deviceID" 
                        value ={deviceID}


                        
                       
                />
                
           </Search>
           </div>
           
           <div>
           <Search>
                    <SearchIconWrapper>
                        <HttpsIcon/>
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Device Password"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange= { evt => setPassword(evt.target.value)}
                        id="fieldname" 
                        value ={password}


                        
                       
                />
                
           </Search>
           </div>
    
           

           <div className = "loginPage_Inner">
   


              
           </div>

          
           <Button  style={{minWidth: '300px', marginLeft: '9%'}} variant="contained" color="success" type='submit' >{buttonName}         </Button> 
           </form>
           </div>
           :
           <div>
             <div>Welcome Back {authData.user.username}</div>
             <Button  style={{minWidth: '300px', borderRadius: 18}} variant="contained" color="success" onClick={()=> logOut()}>{logout}         </Button>

           </div>
           
          
}
           

       
    </div>
    )
}

export default AddFarm;
