// api.js

export async function fetchDevicesList(token, setDeviceData, setLoading, setError) {
    setLoading(true);
    try {
      const headers = new Headers({
        'Authorization': `Token ${token}`
      });
  
      const response = await fetch(`https://nabuapplication.com/api/devices/`, { headers });
      const data = await response.json();
      setDeviceData(data);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }
  