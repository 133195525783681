import React from 'react';
import thermometer from '../../assets/thermometer.png';
import rain from '../../assets/rain.png';
import wind from '../../assets/wind.png';
import wind_arrow from '../../assets/wind_arrow.png';
import  { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import MapWithProps from '../../elements/mapWithProps';

import useIsMobile from '../../hooks/isMobile';



function LandingMain() {

  const isMobile = useIsMobile(); // Use the custom hook to get isMobile


  //const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const containerStyle = {
    width: 'auto',
    //height: '60vh'
    height: isMobile ? '32vh' : '60vh',
  };


const center = {
  lat: 45.06517146310595,
  lng: 7.657733318421136    
};





    return (
        <div className='WSmain'>
          <MediaQuery minWidth={800}>
            <div className='WStitleMain'>Welcome to Nabu</div>
            <div className='map_data'>
                <div className = "mapclass">
                <MapWithProps isMobile={isMobile} containerStyle={containerStyle} center={center} 
                markerPosition={center}
                markerTitle="Nabu Headquarter" />

                </div>

                <div className= "weather_map_right">
                    <div className = "detail_boxe">
                        <div className = "value_box">
                            <img className= "weather_icons" src={thermometer}/>
                            <h1 className='value_type'> 12 °C     
                            </h1>
                        </div>
                        <div className = "div_line"> 
                            <div></div>
                            <div className= "div_line_color"></div>
                            <div></div>
                        </div>
                        <div className = "value_box">
                            <img className="weather_icons"  src={wind}/>
                            <h1 className='value_type'>8 km/h W</h1>
                        </div>
                    </div>
                    <div></div>
                    <div className = "detail_boxe">
                        <div className = "value_box">
                            <img className="weather_icons"  src={rain}/>
                            <h1 className='value_type'>15mm</h1>
                        </div>
                        <div className = "div_line"> 
                            <div></div>
                            <div className= "div_line_color"></div>
                            <div></div>
                        </div>
                        <div className = "value_box">
                            <img className="weather_icons"  src={wind_arrow}/>
                            <h1 className='value_type'>W</h1>
                        </div>
                    </div>             
                </div>
                   
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={799}>
        {/* Render content for screens smaller than 800px */}
        <div className='mobileLandingPageTitle'>Welcome to Nabu</div>

        <MapWithProps isMobile={isMobile} containerStyle={containerStyle} center={center}
        markerPosition={center}
        markerTitle="Nabu Headquarter" />
        <br></br>
        <div className="detail_boxeM">
          <div className="value_boxM">
            <img className="weather_iconsM" src={thermometer} />
            <h1 className='value_type'> 12 °C
            </h1>
          </div>
          <div className="div_line">
            <div></div>
            <div className="div_line_color"></div>
            <div></div>
          </div>
          <div className="value_boxM">
            <img className="weather_iconsM" src={wind} />
            <h1 className='value_type'>8 km/h W</h1>
          </div>
        </div>
        <br></br>
        <div className="detail_boxeM">
          <div className="value_boxM">
            <img className="weather_iconsM" src={rain} />
            <h1 className='value_type'>15mm</h1>
          </div>
          <div className="div_line">
            <div></div>
            <div className="div_line_color"></div>
            <div></div>
          </div>
          <div className="value_boxM">
            <img className="weather_iconsM" src={wind_arrow} />
            <h1 className='value_type'>W</h1>
          </div>
        </div>
        
      </MediaQuery>

    </div>

  );
}

  export default LandingMain;