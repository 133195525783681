// useFetchData.js
import { useState, useEffect } from 'react';

function useFetchDeviceInverterPage(id, token) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [positionNew, setPositionNew] = useState({ lat: null, lng: null });

  useEffect(() => {
    let intervalId;

    async function fetchData() {
      setLoading(true);
      try {
        const headers = new Headers({
          'Authorization': `Token ${token}`
        });

        const response = await fetch(`https://nabuapplication.com/api/devices/${id}`, { headers });
        const data = await response.json();
        setData(data);
        setPositionNew({
          lat: data.coordinates.lat,
          lng: data.coordinates.lng
        });
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    if (token) {
      fetchData();
      //intervalId = setInterval(fetchData, 10000); // Fetch data every 10 seconds
    }

    return () => clearInterval(intervalId); // Clear the interval on unmount
  }, [id, token]);

  return { data, loading, error, positionNew };
}

export default useFetchDeviceInverterPage;
