import React, { useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import MapIcon from '@mui/icons-material/Map';
import TrafficIcon from '@mui/icons-material/Traffic';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import CloudIcon from '@mui/icons-material/Cloud';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
//import AiOutlineIcon from '@mui/icons-material/AiOutline';
import ListOfFields from '../../components/listOfFields';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Button from '@mui/material/Button';
import {Link, useParams} from 'react-router-dom'
import { icon } from 'leaflet';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const SidebarHeader = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [addButton, setAddButton] = useState("Add")

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const sidebarItems = [
    { icon: <MapIcon />, name: 'Maps' , url: '/home/sattview'},
    { icon: <TrafficIcon />, name: 'Sensors' },
    { icon: <LocalFloristIcon />, name: 'Irrigation' },
    { icon: <CloudIcon />, name: 'Weather' },
    { icon: <DnsOutlinedIcon />, name: 'AI' },
  ];
  const sideBarItemPlus = [{icon: <AddCircleOutlineRoundedIcon/>, name : 'Add'}]

  return (
    <div className="containerSideBar">
    <div className={`sidebarM ${isOpen ? 'open' : 'closed'}`}>
      <div className="sidebarM-toggle" onClick={toggleSidebar}>
        {isOpen ? (
          <>
            <ViewSidebarIcon />
            <ChevronLeftIcon />
          </>
        ) : (
          <>
            <ViewSidebarIcon />
            <ChevronRightIcon />
          </>
        )}
      </div>
      <div className="sidebarM-content">
        {sidebarItems.map((item, index) => (
             <Link key={index} to={item.url} className="sidebarM-item">
             {item.icon}
             {isOpen && <span className="sidebarM-item-name">{item.name}</span>}
           </Link>
        ))}
        <hr className="sidebarM-separator" />
        <div className="farm-area">
          <div className="sidebarM-title">Farm Area</div>
          {isOpen && (
           
          <ListOfFields/>
        
          )}
          {sideBarItemPlus.map((item, index) => (
            <Link to="/home/add">
          <div key={index} className="sidebarM-item">
            {item.icon}
            
            {isOpen && <span className="sidebarM-item-name">{item.name}</span>}
          </div>
          </Link>
        ))}
        
          {/* Your additional elements can be added here */}
        </div>
      </div>
    </div>
    </div>
  );
};

export default SidebarHeader;
