import React from 'react';


const GradientLine = () => {
    return (
        <div className="gradient-line-container">
            <div className="label" style={{ left: '2%' }}>%0</div>
            <div className="label" style={{ left: '50%', transform: 'translateX(-50%)' }}>%50</div>
            <div className="label" style={{ right: '1%' }}>%100</div>
            <div className="gradient-line"></div>
        </div>
    );
};

export default GradientLine;   