import React from 'react';
import Typography from '@mui/material/Typography';

import Link from '@mui/material/Link';


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © | All Rights Reserved '}
      <Link color="inherit" href="https://www.nabu.ag/">
        Nabu Srl
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}




function Footer() {

  return (
    <div className="footer">
       
        <Typography
            variant="subtitle1"
            align="center"
            color="text.secondary"
            component="p"
        >
        Intelligence for sustainability!
        <Copyright />
        </Typography>
        
       
    </div>
  );
}

export default Footer;

