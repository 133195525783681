import React, { useEffect, useState } from 'react';
import { useToken } from '../../components/useToken';
import './pivotControl.css';
import MapViewPivot from './mapElement';
import MapView from '../../components/leaFlatFieldMap';
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Stack from '@mui/material/Stack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
//import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import './pivotA.css';

const statusDescriptions = {
    message_type: {
        0x00: "PANEL v0.x E/M",
        // Add more message types if needed
    },
    pivot_controlled: {
        0x00: "MANUAL",
        0x01: "REMOTE"
    },
    functional_status: {
        0x00: "PIVOT fully functional, no problem or fault",
        0x01: "PIVOT not supplied (400VAC 3P)",
        0x02: "General switch OFF",
        0x03: "400V phase fault",
        0x04: "Electrical fault: one or more circuit breakers (QF2-QF3-QF4) open",
        0x05: "PIVOT misaligned",
        0x06: "Water low pressure",
        0x07: "110VAC isolation fault"
    },
    movement_status: {
        0x00: "PIVOT OFF, no movement",
        0x01: "PIVOT ON, no movement",
        0x02: "PIVOT moving FORWARD with automatic stop, if fitted",
        0x03: "PIVOT moving REVERSE with automatic stop, if fitted",
        0x04: "PIVOT moving FORWARD in AUTOREVERSE mode",
        0x05: "PIVOT moving REVERSE in AUTOREVERSE mode"
    },
    irrigation_status: {
        0x00: "DRY, no irrigation",
        0x01: "WET, water irrigation",
        0x02: "FERTIGATION, water + fertilizer irrigation"
    },
    controller_power_status: {
        0x00: "CONTROLLER AC supplied, battery fully charged",
        0x01: "CONTROLLER AC supplied, battery in recharge",
        0x02: "CONTROLLER supplied by battery"
    },
    controller_reboot_reason: {
        0x00: "Normal STARTUP",
        0x01: "Automatic RESET (WATCHDOG)",
        0x02: "Software RESET: automatic by firmware error detection, or manual from remote server or local button",
        0x03: "RESET cause known but unexpected",
        0x04: "RESET cause unknown"
    },
    // Add more mappings as needed
};


const PivotStatusUp = () => {
    const token = useToken();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();
    console.log('deneme' ,id)
    const [fieldData, setFieldData] = useState([]);

    const handlePolygonClick = (polygon) => {
     
    };
    const fetchData = async () => {
        
        try {
            const response = await fetch(`https://nabuapplication.com/api/pivotstatus/?device_info_id=${id}`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setData(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchDataField = async () => {
        
        try {
            const response = await fetch(`https://nabuapplication.com/api/deviceInfo/${id}`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setFieldData(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        if (token) {
            fetchDataField();
            fetchData();
            const interval = setInterval(fetchData, 10000); // Fetch data every 10 seconds
            return () => clearInterval(interval); // Cleanup interval on component unmount
        }
    }, [id, token]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="">
           
            {Array.isArray(data) && data.length > 0 ? (
                data.map(status => (
                    <div className="grid-container">
                    <div></div>
                    <div className='grid-containerVerticalMain'>
                        <div className='mapWraps'>
                            <MapViewPivot    />
                        </div>
                        <div className='MainDownLeftPivot'>
                            <div className='titleMainPagePivotDown'>Pivot Status</div>
    
                            <div className='pivotStatusDetailTable'>
                                <div></div>
                                <div className='pivotStatusDetailLeft'>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Date")}:</span>
                                        <span className="pivotStatusDetailValue"> {`${status.day}/${status.month}/${status.year} ${status.hour}:${status.minute}:${status.second}`}</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Message Type")}:</span>
                                        <span className="pivotStatusDetailValue">{statusDescriptions.message_type[status.message_type] || status.message_type}</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Pivot Controlled")}:</span>
                                        <span className="pivotStatusDetailValue">{statusDescriptions.pivot_controlled[status.pivot_controlled]}</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Functional Status")}:</span>
                                        <span className="pivotStatusDetailValue">{statusDescriptions.functional_status[status.functional_status]}</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Movement Status")}:</span>
                                        <span className="pivotStatusDetailValue">{statusDescriptions.movement_status[status.movement_status]}</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Irrigation Status")}:</span>
                                        <span className="pivotStatusDetailValue">{statusDescriptions.irrigation_status[status.irrigation_status]}</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Duty Cycle")}:</span>
                                        <span className="pivotStatusDetailValue">{status.duty_cycle}%</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Duty Controller Reboot Reason")}:</span>
                                        <span className="pivotStatusDetailValue">{statusDescriptions.controller_reboot_reason[status.controller_reboot_reason]}</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Device ID")}:</span>
                                        <span className="pivotStatusDetailValue">{fieldData.device_ıd}</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                </div>
                                <div></div>
                                <div className='pivotStatusDetailLeft'>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Panel Temperature")}:</span>
                                        <span className="pivotStatusDetailValue">{status.panel_temp}°C</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Panel Humidity")}:</span>
                                        <span className="pivotStatusDetailValue">{status.panel_humidity}%</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Dew Point")}:</span>
                                        <span className="pivotStatusDetailValue">{status.dew_point}°C</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Analog Input 1")}:</span>
                                        <span className="pivotStatusDetailValue">{status.analog_input_1} μA</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Analog Input 2")}:</span>
                                        <span className="pivotStatusDetailValue">{status.analog_input_2} μA</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Analog Input 3")}:</span>
                                        <span className="pivotStatusDetailValue">{status.analog_input_3} μA</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Controller Power Status")}:</span>
                                        <span className="pivotStatusDetailValue">{statusDescriptions.controller_power_status[status.controller_power_status]}</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("Controller Errors")}:</span>
                                        <span className="pivotStatusDetailValue">{status.controller_errors}</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                </div>
                                <div></div>
                            </div>
    
    
                            <div></div>
                        </div>
                    </div>
                    <div></div>
                    <div className='grid-containerVerticalPivot'>
                        <div className='sectionTitle' style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Status
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" startIcon={<StopIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#A6CE39', width: '120px', height: '30px', '&:hover': { backgroundColor: '#94b634' } }}>OFF</Button>
                                <Button variant="contained" startIcon={<CalendarMonthIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>Calendar</Button>
                                <Button variant="contained" startIcon={<PlayArrowIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>Start</Button>
                            </Stack>
                        </div>
    
                        <div className='sectionTitle' style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Direction
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" startIcon={<RotateRightIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>Forward</Button>
                                <Button variant="contained" startIcon={<RotateLeftIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#A6CE39', width: '120px', height: '30px', '&:hover': { backgroundColor: '#94b634' } }}>Reverse</Button>
                            </Stack>
                        </div>
    
                        <div className='sectionTitle' style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Pressure Control
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" startIcon={<CloseIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#A6CE39', width: '120px', height: '30px', '&:hover': { backgroundColor: '#94b634' } }}>Dry</Button>
                                <Button variant="contained" startIcon={<CloseIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>Wet</Button>
                            </Stack>
                        </div>
    
                        <div className='sectionTitle' style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Speed
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#A6CE39', width: '120px', height: '30px', '&:hover': { backgroundColor: '#94b634' } }}>Panel</Button>
                                <Button variant="contained" startIcon={<RotateLeftIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>Sectors</Button>
                            </Stack>
                        </div>
    
                        <div className='sectionTitle' style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Stop on Slot
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#A6CE39', width: '120px', height: '30px', '&:hover': { backgroundColor: '#94b634' } }}>10.1°</Button>
                                <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>0°</Button>
                                <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>0°</Button>
                                <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>0°</Button>
                            </Stack>
                        </div>
    
                        <div className='sectionTitleNot'>
                        <div className="pivotStatusDetailItem">
                                        <span>{("AC Inputs")}:</span>
                                        <span className="pivotStatusDetailValue">{Array.from({ length: 15 }).map((_, index) => (
                            <p key={index}><strong>AC Input {index + 1}:</strong> {status[`ac_input_${index + 1}`] ? 'On' : 'Off'}</p>
                        ))}</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("DC Inputs ")}:</span>
                                        <span className="pivotStatusDetailValue">{Array.from({ length: 6 }).map((_, index) => (
                            <p key={index}><strong>DC Input {index + 1}:</strong> {status[`dc_input_${index + 1}`] ? 'On' : 'Off'}</p>
                        ))}</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                                    <div className="pivotStatusDetailItem">
                                        <span>{("DC Outputs")}:</span>
                                        <span className="pivotStatusDetailValue">{Array.from({ length: 6 }).map((_, index) => (
                            <p key={index}><strong>DC Output {index + 1}:</strong> {status[`dc_output_${index + 1}`] ? 'On' : 'Off'}</p>
                        ))}</span>
                                        <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
                                    </div>
                        
                        </div>
                    </div>
    
                    <div></div>
                </div>
                ))
            ) : (
                <div>No data available</div>
            )}
        </div>
    );
};

export default PivotStatusUp;
