import React, { useState, useEffect } from 'react';
import {Link , useParams} from 'react-router-dom'

import IconGrid from './tryingBard';

function FieldCenter() {
 
   const{ id } = useParams();


  return (
    <div>
        <h1>Details Here for {id}</h1>
        <IconGrid />
    </div>
  );
}

export default FieldCenter;



