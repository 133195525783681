// MapWeatherStation.js
import React from 'react';
import { LoadScript, GoogleMap, MarkerF } from '@react-google-maps/api';

const MapWithProps = ({ isMobile, containerStyle, center, markerPosition, markerTitle }) => {
  const position = { lat: 45.06520556318875, lng: 7.65775477609237 };

  return (
    <LoadScript googleMapsApiKey="AIzaSyC-5-orAsj6wLs_cNDoUvAR7VmDVk6U6w0">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={16}
        mapTypeId={'satellite'}
      >
        <MarkerF
          position={markerPosition}
          label={{
            text: markerTitle,
            color: "#FFFFFF",
            fontSize: isMobile ? '20px' : '30px',
            fontWeight: "bold",
            yOffset: -20,
          }}
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default MapWithProps;
