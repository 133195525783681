import React, { useState, useEffect } from 'react';
import thermometer from '../../assets/thermometer.png';
import rain from '../../assets/rain.png';
import wind from '../../assets/wind.png';
import wind_arrow from '../../assets/wind_arrow.png';
import { Line, Cha } from "react-chartjs-2";
import { useParams } from 'react-router-dom'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import { useToken } from '../../components/useToken';
import MediaQuery from 'react-responsive';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import MapWeatherStation from './mapElement';
import MapWeatherStationMobil from './mapElementMobil';
import { useWeatherStationData } from '../../hooks/fetch_apiDevices';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


import MapWithProps from '../../elements/mapWithProps';


import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.35),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.45),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    marginBottom: 5,
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));


const datawilldete = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "First dataset",
      data: [33, 53, 85, 41, 44, 65],
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)"
    },
    {
      label: "Second dataset",
      data: [33, 25, 35, 51, 54, 76],
      fill: false,
      borderColor: "#742774"
    }
  ]
};

const options = {
  responsive: true,
  maintainAspectRatio: false, // This makes the chart responsive
  scales: {
    x: {
      display: true,
      title: {
        display: false,
        text: 'Month',
      },
    },
    y: {
      display: true,
      title: {
        display: true,
        
      },
    },
  },
};


function WeatherStation() {
    const { id } = useParams();
    const token = useToken()
    //console.log(token)
    const { center, data, loading, error } = useWeatherStationData(id, token); // Use the custom hook
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      // Function to update isMobile when the window is resized
      function handleResize() {
        setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
      }
    
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
    
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const containerStyle = {
      width: 'auto',
      //height: '60vh'
      height: isMobile ? '40vh' : '60vh',
    };
  


    const [fields, setFields] = useState(null);


    useEffect(() => {
        if (data) {
            setFields(data);
        }
    }, [data])


    //const tempData = dataFetch ? dataFetch.map((item) => item.temp).reverse() : [];
    const tempData = fields ? fields.weather_data.map((item) => item.temp).reverse() : [];
    const timeData = fields ? fields.weather_data.map((item) => item.time).reverse() : [];
    const dateStrings = timeData.map(timestamp => {
        // create a Date object for the time stamp
        const date = new Date(timestamp * 1000);

        // Create a custom date string without hours, month, and year
        const customDateString = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear() % 100}`;

        //return customDateString;

        // get a string representation of the date
        return date.toLocaleString();
    });

    

    const humidityData = fields ? fields.weather_data.map((item) => item.humidity).reverse() : [];
    const wind_speedData = fields ? fields.weather_data.map((item) => item.wind_speed).reverse() : [];
    const rainData = fields ? fields.weather_data.map((item) => item.rain).reverse() : [];
    const accrainData = fields ? fields.weather_data.map((item) => item.acc_rain).reverse() : [];
    const uvData = fields ? fields.weather_data.map((item) => item.uv).reverse() : [];
    const lightData = fields ? fields.weather_data.map((item) => item.light).reverse() : [];
    const pressureData = fields ? fields.weather_data.map((item) => item.pressure).reverse() : [];
    const windirectionData = fields ? fields.weather_data.map((item) => item.wind_dir).reverse() : [];



    //const [sendData, setSendData] =useState(tempData.slice(0, 10))
    const [sendData, setSendData] = useState(tempData.slice(0, 10));
    const [sendHumData, setSendHumData] = useState(humidityData.slice(0, 10));
    const [sendWspeedData, setSendWspeedData] = useState(wind_speedData.slice(0, 10));
    const [sendRainData, setSendRainData] = useState(rainData.slice(0, 10));
    const [sendAccRainData, setSendAccRainData] = useState(accrainData.slice(0, 10));
    const [sendUvData, setSendUvData] = useState(uvData.slice(0, 10));
    const [sendLightData, setSendLightData] = useState(lightData.slice(0, 10));
    const [sendPressureData, setSendPressureData] = useState(pressureData.slice(0, 10));
    const [sendWindDirData, setSendWindDirData] = useState(windirectionData.slice(0, 10));
    //const [timeDataView, setTimeDataView] =useState(dateStrings.slice(0, 10))
    const [timeDataView, setTimeDataView] = useState(dateStrings.slice(0, 10));

    useEffect(() => {
        setSendData(tempData.slice(0, 16));
        setTimeDataView(dateStrings.slice(0, 16));
        setSendHumData(humidityData.slice(0, 16));
        setSendWspeedData(wind_speedData.slice(0, 16));
        setSendRainData(rainData.slice(0, 16));
        setSendAccRainData(accrainData.slice(0, 16));
        setSendUvData(uvData.slice(0, 16));
        setSendLightData(lightData.slice(0, 16));
        setSendPressureData(pressureData.slice(0, 16));
        setSendWindDirData(windirectionData.slice(0, 16));
    }, [fields])

    const [typeSelection, setTypeSelection] = React.useState('');


    const containerStyle2 = {
      // width: '70vw',
       width: isMobile ? 'auto' : '70vw',
     height: isMobile ? '30vh' : '60vh'
     //maintainAspectRatio : false
       //width: 'auto',
       //height: '100vh'
       //height: isMobile ? '30vh' : '60vh',
     };

    useEffect(() => {
        const sliceSizes = {
            10: 48,
            20: 96,
            30: 672,
            40: 1344,
        };

        if (sliceSizes.hasOwnProperty(typeSelection)) {
            const size = sliceSizes[typeSelection];
            setSendData(tempData.slice(0, size));
            setTimeDataView(dateStrings.slice(0, size));
            setSendHumData(humidityData.slice(0, size));
            setSendWspeedData(wind_speedData.slice(0, size));
            setSendRainData(rainData.slice(0, size));
            setSendAccRainData(accrainData.slice(0, size));
            setSendUvData(uvData.slice(0, size));
            setSendLightData(lightData.slice(0, size));
            setSendPressureData(pressureData.slice(0, size));
            setSendWindDirData(windirectionData.slice(0, size));
        } else {
            // Handle the default case here if needed
        }
    }, [typeSelection]);

    function createChartData(label, data, backgroundColor, borderColor) {
        return {
            labels: timeDataView,
            //labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
            datasets: [
                {
                    label,
                    data,
                    fill: true,
                    backgroundColor,
                    borderColor,
                },
            ],
        };
    }

    const dataX = createChartData("Temperature", sendData, "rgba(255, 106, 106,0.2)", "rgba(255, 106, 106,1)");
    const Humidity = createChartData("Humidity", sendHumData, "rgba(67, 0, 1,0.2)", "rgba(67, 0, 1,1)");
    const WindSpeed = createChartData("Wind Speed", sendWspeedData, "rgba(255, 218, 123,0.2)", "rgba(255, 218, 123,1)");
    const WindDirection = createChartData("Wind Direction", sendWindDirData, "rgba(194, 124, 160, 0.2)", "rgba(194, 124, 160, 1)");
    const Rain = createChartData("Rain", sendRainData, "rgba(46, 232, 110,0.2)", "rgba(46, 232, 110,1)");
    const AccRain = createChartData("Accumulated Rain", sendAccRainData, "rgba(46, 232, 110,0.2)", "rgba(46, 232, 110,1)");
    const UV = createChartData("UV", sendUvData, "rgba(22, 71, 39,0.2)", "rgba(22, 71, 39,1)");
    const Light = createChartData("Light", sendLightData, "rgba(213, 206, 226, 0.2)", "rgba(213, 206, 226, 1)");
    const Pressure = createChartData("Pressure", sendPressureData, "rgba(75, 3, 213, 0.2)", "rgba(75, 3, 213, 1)");

    if (error) return <h1>Error</h1>
    if (loading) return <Box sx={{ display: 'flex' }}>
                          <CircularProgress />
                        </Box>

    return (

        <div className='WSmain'>
            <MediaQuery minWidth={800}>
                {fields &&
                    <React.Fragment>
                        <div className='WStitle'>Field {fields.name} Weather Station</div>
                    </React.Fragment>
                }
                {fields &&

                    <React.Fragment>
                        <div className='map_data'>
                            {/*<img className = "mapclass" src={annonese_home}/>*/}
                            <div className="mapclass">
                            <MapWithProps isMobile={!isMobile} containerStyle={containerStyle} center={center}  
                            markerPosition={center}
                             markerTitle="Weather Station Position" />
              

                            </div>

                            <div className="weather_map_right">
                                <div className="detail_boxe">
                                    <div className="value_box">
                                        <img className="weather_icons" src={thermometer} />


                                        <h1 className='value_type'> {sendData[0]} °C  </h1>

                                    </div>
                                    <div className="div_line">
                                        <div></div>
                                        <div className="div_line_color"></div>
                                        <div></div>
                                    </div>
                                    <div className="value_box">
                                        <img className="weather_icons" src={wind} />

                                        <h1 className='value_type'>{sendWspeedData[0]} km/h W</h1>
                                    </div>
                                </div>
                                <div></div>
                                <div className="detail_boxe">
                                    <div className="value_box">
                                        <img className="weather_icons" src={rain} />
                                        <h1 className='value_type'>{sendRainData[0]}mm</h1>
                                    </div>
                                    <div className="div_line">
                                        <div></div>
                                        <div className="div_line_color"></div>
                                        <div></div>
                                    </div>
                                    <div className="value_box">
                                        <img className="weather_icons" src={wind_arrow} />
                                        <h1 className='value_type'>{windirectionData[0]}W (angle)</h1>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </React.Fragment>
                }

                <div className='WStables'>
                    <br></br>
                    <div className='WStitle'>Real Time Data</div>
                    <br></br>
                    <div className='weatherStationGraphs'>
                        <Line data={dataX} />

                        <Line data={Humidity} />



                    </div>
                    <div className='weatherStationGraphs'>
                        <Line data={WindSpeed} />

                        <Line data={WindDirection} />



                    </div>
                    <div className='weatherStationGraphs'>
                        <Line data={Rain} />

                        <Line data={UV} />



                    </div>
                    <div className='weatherStationGraphs'>
                        <Line data={Light} />

                        <Line data={Pressure} />



                    </div>
                    <div className='weatherStationGraphs'>
                        <Line data={AccRain} />







                    </div>
                    <br></br>
                    <Search>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select the Available Data Duration</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={typeSelection}
                                label="Select the Available Data Duration"
                                onChange={evt => setTypeSelection(evt.target.value)}
                            >
                                <MenuItem value="">
                                    <em>None</em>

                                </MenuItem>
                                <MenuItem value={10}>Last 12 Hours</MenuItem>
                                <MenuItem value={20}>Last 1 Day </MenuItem>
                                <MenuItem value={30}>Last 1 Week</MenuItem>
                                <MenuItem value={40}>Last 2 Week</MenuItem>

                            </Select>
                        </FormControl>


                    </Search>
                    <br></br>
                    {/*<div className='notifications'>Notifications
                
                </div>
      <Link to={'/'}>Back</Link>*/}

                </div>
            </MediaQuery>
            <MediaQuery maxWidth={799}>
                {/* Render content for screens smaller than 800px */}
                {fields &&
                    <React.Fragment>
                        <div className='WStitleMobil'>{fields.name} Weather Station</div> 
                    </React.Fragment>
                }
                {fields &&


                    <div className='map_data_mobile'>
                        {/*<img className = "mapclass" src={annonese_home}/>*/}

                        {/*<MapWeatherStationMobil center={center} ></MapWeatherStationMobil>*/}

                        <MapWithProps isMobile={isMobile} containerStyle={containerStyle} center={center}
                        markerPosition={center}
                        markerTitle="Weather Station Position" />

                    </div>
                    
                    

                }
                <br></br>
                 <div className="detail_boxeM">
                                    <div className="value_boxM">
                                        <img className="weather_iconsM" src={thermometer} />


                                        <h1 className='value_typeM'> {sendData[0]} °C  </h1>

                                    </div>
                                    <div className="div_line">
                                        <div></div>
                                        <div className="div_line_color"></div>
                                        <div></div>
                                    </div>
                                    <div className="value_boxM">
                                        <img className="weather_iconsM" src={wind} />

                                        <h1 className='value_typeM'>{sendWspeedData[0]} km/h W</h1>
                                    </div>
                                </div>
                                <br></br>
                                <div className="detail_boxeM">
                                    <div className="value_boxM">
                                        <img className="weather_iconsM" src={rain} />
                                        <h1 className='value_typeM'>{sendRainData[0]}mm</h1>
                                    </div>
                                    <div className="div_line">
                                        <div></div>
                                        <div className="div_line_color"></div>
                                        <div></div>
                                    </div>
                                    <div className="value_boxM">
                                        <img className="weather_iconsM" src={wind_arrow} />
                                        <h1 className='value_typeM'>{windirectionData[0]}W (angle)</h1>
                                    </div>
                                </div>
                               

                                <div className='WStables'>

                                <br></br>
                    <div className='WStitleMobil'>Real Time Data</div>
                  
                   
                    <div className='weatherStationGraphsM' >
                        {/*<Line data={datawilldete} options={chartOptions} />

                        <div class="chart-container">
                          <canvas id="chart"></canvas>
                      </div> */}


                      <div className="chart-container" >
                            <Line data={dataX} options={options} containerStyle={containerStyle} />
                          </div>
                        <div className='mobileGraphDivider'> </div>
                          <div className="chart-container" >
                            <Line data={Humidity} options={options} containerStyle={containerStyle} />
                          </div>
                          <div className='mobileGraphDivider'> </div>
                          <div className="chart-container" >
                            <Line data={WindSpeed} options={options} containerStyle={containerStyle} />
                          </div>
                          <div className='mobileGraphDivider'> </div>
                          
                          <div className="chart-container" >
                            <Line data={WindDirection} options={options} containerStyle={containerStyle} />
                          </div>
                          <div className="chart-container" >
                            <Line data={Rain} options={options} containerStyle={containerStyle} />
                          </div>
                          <div className="chart-container" >
                            <Line data={UV} options={options} containerStyle={containerStyle} />
                          </div>
                          <div className="chart-container" >
                            <Line data={Light} options={options} containerStyle={containerStyle} />
                          </div>
                          <div className="chart-container" >
                            <Line data={Pressure} options={options} containerStyle={containerStyle} />
                          </div>
                          <div className="chart-container" >
                            <Line data={AccRain} options={options} containerStyle={containerStyle} />
                          </div>
                          
                       


                    </div>
            
                    <br></br>
              
                    <br></br>
                   
                    {/*<div className='notifications'>Notifications
                
                </div>
      <Link to={'/'}>Back</Link>*/}

                </div>
               
            </MediaQuery>
        </div>



    );


}

export default WeatherStation;