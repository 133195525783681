import { useContext, createContext } from "react";
import React, { useState, useEffect } from 'react';


const AuthContext = createContext(null);

export const AuthProvider = ({user, children}) => {

    const[ authData, setAuthData] = useState(user);

    const  setAuth = newUser => {
        if (newUser){
            localStorage.setItem('nabu-user', JSON.stringify(newUser))
        }
        else{
            localStorage.removeItem('nabu-user')
        }
        setAuthData(newUser);
    }


    return (
        <AuthContext.Provider value={{authData, setAuth}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext);