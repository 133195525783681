import React from "react";
import styled from "styled-components";
// Import the icons you want to use.
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";


const StyledIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const IconGrid = () => {
  const icons = [<HomeIcon />, <NotificationsIcon />, <SettingsIcon />];

  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      {icons.map((icon) => (
        <StyledIcon key={icon}>{icon}</StyledIcon>
      ))}
    </div>
  );
};

export default IconGrid;