import React, { useState, useEffect } from 'react';
import MyMapComponent from './mapElement';
import MenuItem from '@mui/material/MenuItem';
import {  FormControl, InputLabel, Select     } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useParams } from 'react-router-dom';
import { useToken } from '../../components/useToken';


const CompareView = () => {
    // Check if selectedDataset has image_data and bounds that are not default values
    const { id } = useParams();
    const token = useToken()
    const [selectedData, setSelectedData] = useState(null);
    const [selectedDataset, setSelectedDataset] = useState(null);
    const [datasets, setDatasets] = useState([]);
    const [selectedDatasetDate, setSelectedDatasetDate] = useState(null);
    //const [availableDate, setAvailableDate] = useState(null);
    const [datasetsDate, setDatasetsDate] = useState([]);
    const [error, setError] = useState(null);
    const [age, setAge] = React.useState('10');
    const [value, setValue] = useState(null);
    const hasValidData = (
        selectedDataset &&
        selectedDataset.image_data !== 'default_value' &&
        Array.isArray(selectedDataset.bounds) &&
        selectedDataset.bounds.length === 2 &&
        selectedDataset.bounds[0] &&
        selectedDataset.bounds[1]
    );

    const xUndefined = (e) => {

    }; 

    const handleDateChangePicker = (newValue) => {
        console.log("Date picker value:", newValue);
        setValue(newValue);
    };

    const [indexType, setIndexType] = useState('ndvi');

    const handleChangeIndex = (event) => {
        setIndexType(event.target.value);
    };

    useEffect(() => {
        console.log("Date or Index Type changed");
        const formattedDate = value ? value.format('YYYY-MM-DD') : null;
        console.log('Fetching data for date:', value, 'and index type:', indexType);
    
        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });
    
                let url = `https://nabuapplication.com/api/satelliteimage/${id}`;
                const params = new URLSearchParams();
    
                if (formattedDate) {
                    params.append('date', formattedDate);
                }
                if (indexType){
                params.append('index_type', indexType);
                }
    
                const response = await fetch(`${url}?${params.toString()}`, { headers });
                const data = await response.json();
    
                setSelectedDataset(data[0]); // Set the dataset based on the fetched data
                console.log("Fetched data:", data);
    
            } catch (e) {
                setError(e);
            }
        }
    
        if (value || indexType) {
            fetchData();
        }
    }, [value, indexType]); // Depend on both value and indexType

    useEffect(() => {
        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });
    
                const response = await fetch(`https://nabuapplication.com/api/satelliteimage/${id}`, { headers });
                const data = await response.json();
    
                // Once data is fetched, set the first dataset as the default selected dataset
                if (data.length > 0) {
                    setSelectedDataset(data[0]);
                } 
                if (data.length > 0) {
                    setDatasets(data);
                    setSelectedData(data[0]);
                }
            } catch (e) {
                setError(e);
            }
        }
    
        fetchData(); // This invokes the fetchData function.
    }, [id, token]);

    useEffect(() => {
        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });
    
                // Update the URL to match your backend endpoint
                const response = await fetch(`https://nabuapplication.com/api/satelliteimagedates/available_dates/?field_info_id=${id}`, { headers });
                const data = await response.json();
    
                // The dates are in the 'dates' key of the response
                if (data.dates && data.dates.length > 0) {
                    //console.log("dates heree")
                    //console.log(data.dates)
                    setDatasetsDate(data.dates);
                    //setSelectedDateInfo(data.dates[0]);
                } else {
                    // Handle the case where there are no dates
                    setDatasetsDate([]);
                    //setSelectedDatasetDate(null);
                }
            } catch (e) {
                setError(e);
            }
        }
    
        fetchData(); // This invokes the fetchData function.
    }, [id, token]); // Dependencies for useEffect

    useEffect(() => {
        if (value) {
            console.log("valuechanged")
            const formattedDate = value.format('YYYY-MM-DD');
            async function fetchData() {
                try {
                    const headers = new Headers({
                        'Authorization': `Token ${token}`
                    });

                    const response = await fetch(`https://nabuapplication.com/api/satelliteimage/${id}?date=${formattedDate}`, { headers });
                    const data = await response.json();

                    // Assuming the API returns the dataset for the selected date
                    setSelectedData(data[0]);    // this is the element that we are rendering !!!!!!!!!
                    console.log("fetched")
                    console.log(data)
                    console.log("fetched")

                } catch (e) {
                    setError(e);
                }
            }

            fetchData();
        }
    }, [value]); // Effect runs when 'value' changes

    const isDateAvailable = (date) => {
        // Create a new Date object to avoid mutating the original date
    let adjustedDate = new Date(date);

    // Subtract one day
    adjustedDate.setDate(adjustedDate.getDate() + 1);
    const formattedDate = adjustedDate.toISOString().split('T')[0];
    return datasetsDate.includes(formattedDate);
};


    return (
        <div className=''>
            {hasValidData ? (
                <div className='sattImageLeaflatMap'>

                    
           
                    
                    <MyMapComponent
                        bounds={selectedData.bounds}
                        imageBase64={selectedData.image_data}
                    />
                         <div className=' compareView_selections'>
                         <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Index</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={indexType}
                            label="Select View"
                            onChange={handleChangeIndex}
                        >
                            <MenuItem value={'ndvi'}>NDVI</MenuItem>
                            <MenuItem value={'ndre'}>NDRE</MenuItem>
                            <MenuItem value={'ndmi'}>NDMI</MenuItem>
                        </Select>
                
                    </FormControl>
                </div>
                <div className='compareView_selectionsDate'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                        label="DD/MM/YYYY"
                        value={value}
                        onChange={handleDateChangePicker}
                     
                        renderInput={(params) => <TextField {...params} />}
                        shouldDisableDate={(date) => !isDateAvailable(date)}
                        
                        
                    />
                    </LocalizationProvider>
                    </div>
                    <h4 className='compareView_selectionsDate' > Average Vegetation: {selectedData.average.toFixed(3)}</h4> 
                </div>
            ) : (
                <p>There is no data recorded for this day or the dataset is incomplete.</p>
            )}
            <div></div>
        
        </div>
    );
};

export default CompareView;