import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FetchData from '../../components/fetch_data';
import MainFieldNavBar from '../mainField_NavBar';
import WeatherStation from '../../pages/WeatherStationPageMain/weatherStation';
import FieldCenter from '../../debugP/fieldCenter';
import LandingMain from '../../pages/LandingPageMain/LandingMain';
import AddField from '../../OldFilesWillBeDeletedOneDayIhope/addField';
import AddFarm from '../../OldFilesWillBeDeletedOneDayIhope/addFarm';
import Auth from '../../components/auth';
import AddGeneral from '../../components/generalAdd';
import FarmPage from '../../pages/FarmPageMain/farmPage';
import InverterMain from '../../pages/inverterPageMain//inverterMain';
import Search from '../../pages/inverterPageMain//inverterLanding';
import SattImagesLanding from '../../components/sattImages/sattImagesLanding';
import SattImageMain from '../../components/sattImages/sattImageMain';

//import AddGeneral from '../generalAdd';




function MainM() {

  return (
    <div className='main_section'>
        
        <h4 className='mainMainSectionM'>   
            <Routes>
                <Route exact path ='/' element={<LandingMain></LandingMain>}></Route>
                
                <Route path ="details/:id" element={<FieldCenter></FieldCenter>}></Route>
                <Route exact path ="farm/:id" element={<FarmPage></FarmPage>}></Route>
                <Route exact path ="field/:id" element={<FarmPage></FarmPage>}></Route>
                <Route exact path ="weather/:id" element={<WeatherStation></WeatherStation>}></Route>
                <Route exact path ="addfield" element={<AddField></AddField>}></Route>
                <Route exact path ="addfarm" element={<AddFarm></AddFarm>}></Route>
                <Route exact path ="add" element={<AddGeneral></AddGeneral>}></Route>
                <Route exact path ="inverter/:id" element={<InverterMain></InverterMain>}></Route>
                <Route exact path ="inverter" element={<Search></Search>}></Route>
                <Route exact path ="sattview" element={<SattImagesLanding></SattImagesLanding>}></Route>
                <Route exact path ="sattview/:id" element={<SattImageMain></SattImageMain>}></Route>
                    
                
            </Routes>
            
        </h4>
        
    </div>
  );
}

export default MainM;
