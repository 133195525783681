import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';

const GoogleMutantLayer = ({ type = 'satellite' }) => {
  const map = useMap();

  useEffect(() => {
    const checkGoogleLoaded = setInterval(() => {
      if (window.google) {
        clearInterval(checkGoogleLoaded);
        const googleMutantLayer = L.gridLayer.googleMutant({
          type
        }).addTo(map);

        return () => {
          map.removeLayer(googleMutantLayer);
        };
      }
    }, 100); // Check every 100ms

    setTimeout(() => {
      clearInterval(checkGoogleLoaded);
    }, 10000); // Stop checking after 10 seconds

  }, [map, type]);

  return null;
};

export default GoogleMutantLayer;
