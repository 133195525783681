import React from 'react';
import { useEffect, useState  } from 'react';
import logo_header from '../assets/logo_header.png'
import user from '../assets/user.png'
import home from '../assets/house.png'
import settings from '../assets/globe.png'
import notification from '../assets/envelope.png'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import NotificationsRounded from '@mui/icons-material/NotificationsRounded';
import LogoutRounded from '@mui/icons-material/LogoutRounded';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import {useAuth} from "../hooks/useAuth";
import DropdownMenu from './headerDropDown';
import AccountMenu from './headerDropDown';
import useIsMobile from '../hooks/isMobile';

import SidebarHeader from './mobileSideBarElements/mobileSideBarFromHeader';









function Header() {

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [showSidebar, setShowSideBar] = useState(false);


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const isMobile = useIsMobile(); // Use the custom hook to get isMobile

    const [showMenu, setShowMenu] = React.useState(false);
    //const authData = JSON.parse(localStorage.getItem('nabu-user'));
    const { authData, setAuth} = useAuth();

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };
    
    const logOut = () => {
      setAuth(null);
      window.location.href = '/';
    }

    const backHome = () => {
      window.location.href = '/home';
      //setShowSideBar(!showSidebar);
    }
    const openSideBar = () => {
      //window.location.href = '/home';
      setShowSideBar(!showSidebar);
    }

    const handleCloseSideBar = () => {
      setShowSideBar(false);
    };

   

  return (
    <div className="">
      
    {isMobile ? (
        <div className="header_mobil">
          <div></div>
          <FormatListBulletedIcon className='logo_cont_MUI' onClick={openSideBar} sx={{ fontSize: 30 }}></FormatListBulletedIcon>
          
        {showSidebar && (
        <SidebarHeader onClose={handleCloseSideBar}>
          {/* Content of the modal, such as the settings form */}
          {/* Add your settings form components here */}
        </SidebarHeader>
      )}
        
        <div> </div>
        <img src ={logo_header} alt="main logo" className='logo_cont_mobil'></img>
        <div>
          
        </div>
        <div></div>
        <div></div>
        {/*<div className='profile_name_mobil'> {authData && <p6>{authData.user.username}</p6>}</div>*/}
        <PersonRoundedIcon className='logo_cont_MUI'  sx={{ fontSize: 30 } } onClick={toggleDropdown}></PersonRoundedIcon>
        {isDropdownOpen && <AccountMenu />}
        
        
     
    </div>
      ) : (
    <div className="header">
        <HomeRoundedIcon className='logo_cont_MUI' onClick={backHome} sx={{ fontSize: 45 }}></HomeRoundedIcon>
        
        <div> </div>
        <img src ={logo_header} alt="main logo" className='logo_cont'></img>
        <div>
          
        </div>
        <div></div>
        <PersonRoundedIcon className='logo_cont_MUI'  sx={{ fontSize: 45 } } onClick={toggleDropdown}></PersonRoundedIcon>
        {isDropdownOpen && <AccountMenu />}
        
        {/*<div className='profile_name'> {authData && <p6>{authData.user.username}</p6>}</div>*/}
        
        {showMenu && (
        <div className="settings-menu">
          {/* menu items */}
          <h9>item</h9>
        </div>
      )}
      <NotificationsRounded className='logo_cont_MUI'  sx={{ fontSize: 45 }} ></NotificationsRounded>
      
      <LogoutRounded className='logo_cont_MUI' onClick={()=> logOut()} sx={{ fontSize: 45 }} ></LogoutRounded>
    </div> )}
    </div>
  );
  }

export default Header;


