import '../../App.css';
import React, { useEffect, useState } from 'react';


import Main from '../../layoutElements/main';
import SideBar from '../../layoutElements/sidebar';
import Header from '../../layoutElements/header';

import Footer from '../../layoutElements/footer';
import MobileSidebar from '../../layoutElements/mobileSideBarElements/mobilesideBar';

import useIsMobile from '../../hooks/isMobile';





function HomePage() {
 
  const isMobile = useIsMobile(); // Use the custom hook to get isMobile


  return (
    <div className="App">
      {!isMobile && <Header />}

      {isMobile ? (
        // Mobile Version
        <div className="general_content-mobile">
          <MobileSidebar></MobileSidebar>
          {/*<Main />*/}
        </div>
      ) : (
        // Desktop Version
        <div className="general_content">
          <div></div>
          <SideBar />
          <Main />
          <div></div>
        </div>
      )}

      <Footer></Footer>
    </div>
  );
}

export default HomePage;
/*

  function HomePage() {

      return (
        
          <div className="App">
          <Header/>
          <div className='general_content'>
            <div></div>
            <SideBar/>
            <Main/>
            <div></div>
          </div>
          
          <Footer></Footer>

        </div>
      );
    }
    
    export default HomePage;
    */