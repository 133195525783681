import React, { useState, useEffect } from 'react';
import {Link , useParams} from 'react-router-dom'

import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import {getFarms, getFields} from '../services/field_services';
import {useAuth} from "../hooks/useAuth";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import Alert from '@mui/material/Alert';

import './auth.css';

import logo from '../assets/logo_nabu_login.png';
import PersonIcon from '@mui/icons-material/Person';
import HttpsIcon from '@mui/icons-material/Https';
import Button from '@mui/material/Button';
import { useToken } from './useToken';




import { purple } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { auth } from "../services/user_services";
import { register } from "../services/user_services";

import { useFarmData } from '../hooks/fetch_apiFarm';
import { useFarmDataDe } from '../hooks/generalAddFetchTry';
import { useFetchFields } from '../hooks/fetch_apiFields';


const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: purple[500],
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#11cb5f',
      },
    },
  });

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.35),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.45),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    marginBottom: 5,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '30ch',
      },
    },
  }));
  const StyledInputBasePass = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        //width: '24.5ch',
        width: '30ch',
      },
    },
  }));
 

function AddGeneral() {
    const token = useToken()
    const [dataFarms, setDataFarms] = useState(null);
    const [dataFields, setDataFields] = useState(null);
    const [fieldID, setFiedID] = useState(null);
    const [farmName, setFarmName] = React.useState('');
    const [deviceName, setDeviceName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const{ id  } = useParams();
    const { dataF, loadin, erro } = useFarmDataDe(token ); // Use the custom hook
    //console.log("Response Data:", dataF); // This will print the response data to the console
    const { dataField } = useFetchFields(token ); // Use the custom hook

    

    const { authData, setAuth} = useAuth();
    const userId = authData.user.id;
    
  
    const filteredData = dataF ? dataF.filter(item => item.user_id === userId): [];
    //const filteredData = dataFar ? dataFar.filter(item => item.user_id === userId): [];

    const filteredDataforFarm = dataField && farmName ? dataField.filter(item => item.farm_info=== farmName): [];

 
   //const{ id } = useParams();

   const url = 'https://nabuapplication.com/api/farms/';

   



    const [username, setUsername] = useState('');
    const [fieldName, setFieldName] = useState('');
    const [deviceID, setdeviceID] = useState('');
    const [password, setPassword] = useState('');
    const [errorStr, setErrorStr] = useState('');
    
    const [email, setEmail] = useState('');
    //const { authData, setAuth} = useAuth();
    const { regData, setRegData} = useAuth();
    const [userID, setUserId] = useState(null)
 
    const data2 = {
        name: fieldName,
        //user_id: authData.user.id,
        device_ıd: deviceID,
        farm_info: farmName
      };
   
    //const [isLoginView, setIsLoginView] = useState(true);

    //const [token, setToken] = useCookies(['mr-token']);

    const [buttonName, setbuttonName] = useState("Register Now!")
    const [logout, setLogout] = useState("Register")

    /*useEffect(() => {
        console.log(token);
        if(token['mr-token']) window.location.href = '/';
    }, [token])  */


    const isDisabled = username.length ===0 || password.length === 0;






      const logOut = () => {
        setAuth(null);
      }


    const [typeSelection, setTypeSelection] = React.useState('');
    //const url = 'http://127.0.0.1:8000/api/farms/';
    const [selecturl, setUrl] = useState("");
    const [data, setData] = useState({});


    useEffect(() => {
        switch(typeSelection) {
            case 10:
            setUrl('https://nabuapplication.com/api/farms/')
            break;
            case 20:
            setUrl("https://nabuapplication.com/api/fields/")
            break;
            case 30:
              setUrl("https://nabuapplication.com/api/devices/")
              break;
            default:
            setUrl("")
        }
    }, [typeSelection])
 


    const handleSubmit = async e => {
      e.preventDefault();
      let data;
      switch(typeSelection) {
          case 10:
          data = {
              name: username,
              user_id: authData.user.id,
          };
          break;
          case 20:
          data = {
              name: fieldName,
              //device_ıd: deviceID,
              farm_info: farmName
          };
          break;
          case 30:
            data = {
                name: deviceName,
                device_ıd: deviceID,
                field_info: fieldID, 
                device_type: "weather_station"
            };
            break;
          default:
          data = {};
        }
        //setLoading(true);
        try {
            const response = await fetch(selecturl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify(data)
            });
            const json = await response.json();
            if (!response.ok) {
                setErrorStr(json.error);
            } else {
                setErrorStr("Successful registration, go back to home to see changes");
            }
            //setLoading(false);
        } catch (err) {
            setErrorStr("Something went wrong, please try again later");
            //setLoading(false);
        }
      /*fetch(selecturl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        
       
          .then(response => response.json())
          .then(response => console.log(response))
          .then(result => console.log(result))
          //.then(result => setErrorStr(result.name[0]))
          .catch(error => console.log(error))
          //.then(response => setSetErrorStr(response.json().name)) */
      }
          

  
    return (
        <div className="generalAddM">
            
             
            <h5 className="addPageTittle" > Select the operation type</h5>
            {!regData ? 
            <div className='addPageSizeManage'>
  
            <form onSubmit={handleSubmit}>
                <Search>
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select the Method</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={typeSelection}
                            label="Select the Method"
                            onChange={evt => setTypeSelection(evt.target.value)}
                            >
                            <MenuItem value="">
                                <em>None</em>
                
                            </MenuItem>
                            <MenuItem value={10}>Add New Farm</MenuItem>
                            <MenuItem value={20}>Add New Field </MenuItem>
                            <MenuItem value={30}>Add New Device to Any Field</MenuItem>
                            </Select>
                    </FormControl>
        
                </Search>

                <div>
                    {typeSelection === 10 ? 
                    <div>
                        <h5 className="addPageTittle" >  Please, Enter the Farm Name </h5>           
                        <Search>
                            <SearchIconWrapper>
                                <BadgeOutlinedIcon/>
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="New Farm Name"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange= { evt => setUsername(evt.target.value)}
                                id="fieldname" 
                                value ={username}
                            />
                        </Search>
                        
                        <br></br>
   

                        <Button  style={{minWidth: '', marginLeft: '9%'}} variant="contained" color="success" type='submit' >{buttonName}         </Button> 

                    </div> : 
                    typeSelection === 20 ? 
                    <div> 
                                                
                        <Search>
                            <SearchIconWrapper>
                                <PersonIcon/>
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Field Name"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange= { evt => setFieldName(evt.target.value)}
                                id="fieldname" 
                                value ={fieldName}
                            />     
                        </Search>
                        
                        <Search>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select The Farm to Assign</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={farmName}
                                    label="The Farm to Assign"
                                    onChange={evt => setFarmName(evt.target.value)}
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {filteredData.map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                                {/*<div>{farmName}</div>
                                <div>{fieldName}</div>*/}
                        
                        </Search>
                        <Button  style={{minWidth: '', marginLeft: '9%'}} variant="contained" color="success" type='submit' >{buttonName}         </Button> 
                    </div> :
                    typeSelection === 30 ? 
                    <div> 
                        This div will be rendered when typeSelection is equal to (debug)
                        <Search>
                            <SearchIconWrapper>
                                <PersonIcon/>
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Device Name"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange= { evt => setDeviceName(evt.target.value)}
                                id="deviceName" 
                                value ={deviceName}
                            />     
                        </Search>
                        
                        <Search>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select The Farm to Assign</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={farmName}
                                    label="Select The Farm to Assign"
                                    onChange={evt => setFarmName(evt.target.value)}
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {filteredData.map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                       {item.name}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                                {/*<div>{farmName}</div>
                                <div>{fieldName}</div>*/}
                        
                        </Search>
                        <Search>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select The Field to Assign</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={fieldID}
                                    label="Select The Field to Assign"
                                    onChange={evt => setFiedID(evt.target.value)}
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {filteredDataforFarm.map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                       {item.name}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                                {/*<div>{farmName}</div>
                                <div>{fieldName}</div>
                                    <div>{fieldID}</div>*/}
                        
                        </Search>
                        <Search>
                                <SearchIconWrapper>
                                    <PersonIcon/>
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Device ID"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange= { evt => setdeviceID(evt.target.value)}
                                    id="deviceID" 
                                    value ={deviceID}


                                    
                                  
                            />
                            
                        </Search>
                        <Button  style={{minWidth: '', marginLeft: '9%'}} variant="contained" color="success" type='submit' >{buttonName}         </Button> 

                    </div> :
                    null}
                </div>
                
   
           </form>
           <br></br>
           <div>
           {errorStr ? (errorStr === "Successful registration, go back to home to see changes" ?
                <Alert className='notification' severity="success">{errorStr}</Alert> :
                <Alert className='notification' severity="error">{errorStr}</Alert>
            ) : null}
           
           </div>
           </div>
           :
           <div>
             <div>Welcome Back {authData.user.username}</div>
             <Button  style={{minWidth: '300px', borderRadius: 18}} variant="contained" color="success" onClick={()=> logOut()}>{logout}         </Button>

           </div>
           
           
          
        }
      

       
    </div>
    )
}

export default AddGeneral;
