import React, { useState } from 'react';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 400px;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
`;

const Select = styled.select`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  height: 150px;
`;

const Button = styled.button`
  padding: 0.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #0077ff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #0052cc;
  }
`;

function SettingsForm() {
  const [startTime, setStartTime] = useState('');
  const [duration, setDuration] = useState('');
  const [daysOfWeek, setDaysOfWeek] = useState([]);

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };

  const handleDaysOfWeekChange = (event) => {
    const selectedDays = Array.from(event.target.selectedOptions, (option) => option.value);
    setDaysOfWeek(selectedDays);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // TODO: Add logic to save settings to backend
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputWrapper>
        <Label htmlFor="start-time">Start Time:</Label>
        <Input type="time" id="start-time" value={startTime} onChange={handleStartTimeChange} />
      </InputWrapper>
      <InputWrapper>
        <Label htmlFor="duration">Duration (in minutes):</Label>
        <Input type="number" id="duration" value={duration} onChange={handleDurationChange} />
      </InputWrapper>
      <InputWrapper>
        <Label htmlFor="days-of-week">Days of Week:</Label>
        <Select multiple id="days-of-week" value={daysOfWeek} onChange={handleDaysOfWeekChange}>
          <option value="monday">Monday</option>
          <option value="tuesday">Tuesday</option>
          <option value="wednesday">Wednesday</option>
          <option value="thursday">Thursday</option>
          <option value="friday">Friday</option>
          <option value="saturday">Saturday</option>
          <option value="sunday">Sunday</option>
        </Select>
      </InputWrapper>
      <Button type="submit">Save Settings</Button>
    </Form>
  );
}

export default SettingsForm;
