import React, {useState, useEffect} from "react"; 

//import {useCookies} from 'react-cookie';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';


import './auth.css';

import logo from '../assets/logo_nabu_login.png';
import SearchIcon from '@mui/icons-material/Search';

import PersonIcon from '@mui/icons-material/Person';
import HttpsIcon from '@mui/icons-material/Https';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
//import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';


import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

import { purple } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { auth } from "../services/user_services";
import {useAuth} from "../hooks/useAuth";
import {Link} from 'react-router-dom'
import { register } from "../services/user_services";


const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: purple[500],
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#11cb5f',
      },
    },
  });

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.35),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.45),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    marginBottom: 5,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '30ch',
      },
    },
  }));
  const StyledInputBasePass = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        //width: '24.5ch',
        width: '30ch',
      },
    },
  }));
 



function Register() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [email, setEmail] = useState('');
    const { authData, setAuth} = useAuth();
    //const [isLoginView, setIsLoginView] = useState(true);

    //const [token, setToken] = useCookies(['mr-token']);

    const [buttonName, setbuttonName] = useState("Register")
    const [logout, setLogout] = useState("Register")

    /*useEffect(() => {
        console.log(token);
        if(token['mr-token']) window.location.href = '/';
    }, [token])  */


    const isDisabled = username.length ===0 || password.length === 0;


    const passMatch = () => {
        return password === password2;
    }


    const handleSubmit = async e => {
        e.preventDefault();
        if(passMatch()){
            const regData = await register({username, email, password, profile:{is_premium:false}});
            if(regData){
                console.log(regData);
            }
            console.log('all good', username, password,email);
            window.location.href = '/'; 
        } else {
            console.log('pass dont match');
        }

  
      }

      const logOut = () => {
        setAuth(null);
      }

    return (
        <div className="Login_page">
            
             <img src={logo} className = "logo_login_page" />
            <h1 className="font-link_robota" >Register </h1>
            {!authData ? 
            <div>
            <form onSubmit={handleSubmit}>
            <div>            
                <Search>
                    <SearchIconWrapper>
                        <PersonIcon/>
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Username"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange= { evt => setUsername(evt.target.value)}
                        id="username" 
                        value ={username}


                        
                       
                />
                
           </Search></div>


           <div>
           <Search>
                <SearchIconWrapper>
                    <HttpsIcon/>
                </SearchIconWrapper>
                <StyledInputBasePass
                    placeholder="Password"
                    inputProps={{ 'aria-label': 'search' }}
                    id="outlined-adornment-password"
                    //type={values.showPassword ? 'text' : 'password'} 
                    type={'password'} 
                    value ={password}
                    onChange= { evt => setPassword(evt.target.value)}
                />
           </Search>
           </div>
           
           <div>
           <Search>
                <SearchIconWrapper>
                    <HttpsIcon/>
                </SearchIconWrapper>
                <StyledInputBasePass
                    placeholder="Repeat Password"
                    inputProps={{ 'aria-label': 'search' }}
                    id="outlined-adornment-password"
                    //type={values.showPassword ? 'text' : 'password'} 
                    type={'password'} 
                    onChange= { evt => setPassword2(evt.target.value)}
                />
           </Search>
           </div>
           <div>
           <Search>
                <SearchIconWrapper>
                    <MarkEmailUnreadOutlinedIcon/>
                </SearchIconWrapper>
                <StyledInputBasePass
                    placeholder="E-Mail"
                    inputProps={{ 'aria-label': 'search' }}
                    id="outlined-adornment-password"
                    //type={values.showPassword ? 'text' : 'password'} 
                   
                    onChange= { evt => setEmail(evt.target.value)}
                />
           </Search>
           </div>
           

           <div className = "loginPage_Inner">
               <div>
                    <ThemeProvider theme={theme} >
                       
                    <h3 className = " forgot_pass_register_now" >  
                        <Link   to= {'/'} >
                          <Typography>    Go Back to Log In!
                          </Typography>
                        </Link>  
                          
                    </h3>   
                        
                    </ThemeProvider>
               </div>


              
           </div>

          
           <Button  style={{minWidth: '300px', marginLeft: '9%'}} variant="contained" color="success" type='submit' >{buttonName}         </Button> 
           </form>
           </div>
           :
           <div>
             <div>Welcome Back {authData.user.username}</div>
             <Button  style={{minWidth: '300px', borderRadius: 18}} variant="contained" color="success" onClick={()=> logOut()}>{logout}         </Button>

           </div>
           
          
}
           

       
    </div>
    )
}

export default Register;