/*export function auth(credentials) {
    //return fetch('http://127.0.0.1:8000/api/authenticate/', {
    return fetch('https://nabuapplication.com/api/authenticate/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(resp => resp.json())
    .catch( e => {
        console.log(e)
    })
}*/

export function auth(credentials) {
    return fetch('https://nabuapplication.com/api/authenticate/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
    .then(resp => {
        if (resp.ok) {
            return resp.json();
        } else {
            throw new Error('Authentication failed: ' + resp.statusText);
        }
    })
    .catch(e => {
        console.log(e);
        //throw new Error('An error occurred during authentication: ' + e.message);
        throw new Error('Authentication failed: Please, Check your credentials');
    });
}


export function register(userData) {
    //return fetch('http://127.0.0.1:8000/api/users/', {
    return fetch('https://nabuapplication.com/api/users/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
    }).then(resp => resp.json())
    .catch( e => {
        console.log(e)
    })
}