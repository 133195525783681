import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, ImageOverlay } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

// Adjusting the default icon path
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;


const MyMapComponent = ({ bounds, imageBase64, zoomLevel = 13 }) => {
    // If you have specific bounds for your image, you can set them here.
    // For this example, I'm using a small bounding box around the center for demonstration.

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
      function handleResize() {
        setIsMobile(window.innerWidth < 768);
      }
      window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mapStyle = {
    width: '100%',
    height: isMobile ? '300px' : '600px'
  };

    
    
  
    return (
      <MapContainer bounds={bounds} zoom={zoomLevel} style={mapStyle}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
       
        <ImageOverlay
          url={imageBase64}
          bounds={bounds}
        />
      </MapContainer>
    );
  }
export default MyMapComponent;
