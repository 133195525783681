import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MyMapComponent from './mapElement'; 
import { useToken } from '../../components/useToken';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import {  FormControl, InputLabel, Select     } from '@mui/material';
import { styled } from '@mui/system';
//import { Input, inputClasses } from '@mui/base/Input';
import { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CloseOutlined, ConstructionOutlined } from '@mui/icons-material';
import { Line } from "react-chartjs-2";
import CompareView from './compareView';
import GradientLine from './gradientElement';
import DataCard from './dataCard';
import MediaQuery from 'react-responsive';
import Modal from '../../components/sattImages/settingsSatImage';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useFetchFields } from '../../hooks/fetchSingeField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';


import CircularProgress from '@mui/material/CircularProgress';


const parentStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '70vh' // Set the height of the parent (e.g., full viewport height)
  };


const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  
  const dataasd = {
    labels: [
      '2023-04-15', '2023-04-20', '2023-04-25', '2023-04-30', '2023-05-05', 
      '2023-05-10', '2023-05-15', '2023-05-20', '2023-05-25', '2023-05-30', 
      '2023-06-05', '2023-06-10', '2023-06-15', '2023-06-20', '2023-06-25', 
      '2023-06-30', '2023-07-05', '2023-07-10', '2023-07-15', '2023-07-20', 
      '2023-07-25', '2023-07-30', '2023-08-05', '2023-08-10', '2023-08-15', 
      '2023-08-20', '2023-08-25', '2023-08-30', '2023-09-05', '2023-09-10', 
      '2023-09-15', '2023-09-20'
    ],
    datasets: [
      {
        label: 'Actual Vegetation',
        data: [0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.90, 0.90, 0.85, 0.80, 0.75, 0.70, 0.65, 0.60, 0.55, 0.50, 0.40, 0.35, 0.30, 0.25, 0.20],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      },
      {
        label: 'Target Vegetation',
        data: [0.19, 0.26, 0.29, 0.39, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.70, 0.80, 0.90, 1.00, 1.10, 1.20, 1.30, 1.35, 1.40, 1.35, 1.30, 1.25, 1.20, 1.10, 1.00, 0.95, 0.90, 0.80, 0.75, 0.70, 0.60, 0.55],
        fill: false,
        borderColor: 'rgb(192, 75, 75)',
        tension: 0.1
      },
      {
        label: 'Past Vegetation',
        data: [0.01, 0.3, 0.10, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.75, 0.75, 0.75, 0.70, 0.65, 0.60, 0.55, 0.50, 0.45, 0.40, 0.35, 0.30, 0.25, 0.20, 0.15, 0.10],
        fill: false,
        borderColor: 'rgb(111, 23, 102)',
        tension: 0.1
      }
    ]
  };



function SattImageMainUpdate() {
    const { id } = useParams();
    const [datasets, setDatasets] = useState([]);
    const [datasetsDate, setDatasetsDate] = useState([]);
    const [vegetationValue, setVegetationValue] = useState([]);
    const [vegetationDates, setVegetationDates] = useState([]);
    const [selectedDataset, setSelectedDataset] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [isNormChecked, setIsNormChecked] = useState(false);
    const handleCheckboxNormChange = (event) => {
        setIsNormChecked(event.target.checked);
    };

    const [selectedDatasetDate, setSelectedDatasetDate] = useState(null);
    const [error, setError] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const token = useToken()

    //const{ id } = useParams();
    //const token = useToken()
    const { dataField, loadin, erro } = useFetchFields(token, id);
    //console.log("here dataField",dataField)
    

    const [age, setAge] = React.useState('10');
    const [value, setValue] = useState(null);
    const [curveValue, setCurveValue] = useState(null);

    //const [value, setValue] = useState(dayjs('2022-04-17'));

    const [isChecked, setIsChecked] = useState(false);

    // Handle change event of the checkbox
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleChangeView = (event) => {
        setAge(event.target.value);
      };
    

    // ... your token and fetchData code ...

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDateSelect = (dataset) => {
        //const selectedDate = String(dataset.date);
        //setTypeSelection(selectedDate); 
        setSelectedDataset(dataset);
        //setTypeSelection(String(dataset.date));
        handleClose();
    };


    useEffect(() => {
        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });
    
                const response = await fetch(`https://nabuapplication.com/api/satelliteimage/${id}?index_type=ndvi`, { headers });
                const data = await response.json();
    
                // Once data is fetched, set the first dataset as the default selected dataset
                if (data.length > 0) {
                    setSelectedDataset(data[0]);
                } 
                if (data.length > 0) {
                    setDatasets(data);
                    for (let i = 0; i < data.length; i++) {
                        // Check if the average is not 1
                        if (data[i].average !== 1) {
                            setSelectedDataset(data[i]);
                            return;  // Exit the function once the appropriate dataset is found
                        }
                    }
                    // this part done for temp because of eleminating broken data. in future it is better to drop them from backend or db side above loop checks if data broken which is avg is 1 and takes previos data to load on page
                    //setSelectedDataset(data[0]);  
                    //console.log("data:",data[1])
                }
            } catch (e) {
                setError(e);
            }
        }
    
        fetchData(); // This invokes the fetchData function.
    }, [id, token]);

    

    useEffect(() => {
        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });
    
                // Update the URL to match your backend endpoint
                const response = await fetch(`https://nabuapplication.com/api/satelliteimagedates/available_dates/?field_info_id=${id}`, { headers });
                const data = await response.json();
    
                // The dates are in the 'dates' key of the response
                if (data.dates && data.dates.length > 0) {
                    //console.log("dates heree")
                    //console.log(data.dates)
                    setDatasetsDate(data.dates);
                    //setSelectedDateInfo(data.dates[0]);
                } else {
                    // Handle the case where there are no dates
                    setDatasetsDate([]);
                    setSelectedDatasetDate(null);
                }
            } catch (e) {
                setError(e);
            }
        }
    
        fetchData(); // This invokes the fetchData function.
    }, [id, token]); // Dependencies for useEffect

    useEffect(() => {
        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });
    
                // Update the URL to match your backend endpoint
                const response = await fetch(`https://nabuapplication.com/api/satelliteimageaverages/average_list?field_info_id=${id}&year=${selectedYear}`, { headers });
                const data = await response.json();
                //console.log("dates heree average")
                //console.log(data)
    
                // The dates are in the 'dates' key of the response
                if (data.data && data.data.length > 0) {
                    const datesVegetation = data.data.map(item => {
                        const dateObj = new Date(item.date);
                        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
                        const day = dateObj.getDate().toString().padStart(2, '0');
                        return `${month}-${day}`;
                    });
                    const averagesVegetation = data.data.map(item => item.average * 100);
                    //console.log("dates heree average")
                    //console.log(data.dates)
                    //console.log("avarage veg:", averagesVegetation)
                   
                        setVegetationValue(averagesVegetation);
                        setVegetationDates(datesVegetation);
                   
                    
                    //setSelectedDateInfo(data.dates[0]);
                } else {
                    // Handle the case where there are no dates
                    setVegetationValue([]);
                    setVegetationDates([]);
                    //setSelectedDatasetDate(null);
                }
            } catch (e) {
                setError(e);
            }
        }
        
    
        fetchData(); // This invokes the fetchData function.
    }, [selectedYear,id, token]); // Dependencies for useEffect

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    //console.log("avarage veg after:", vegetationValue)
    const graphOptions = {
        responsive: true,
        maintainAspectRatio: false, // This allows setting a custom aspect ratio
        //aspectRatio: 1.2, // Adjust this value as needed
        scales: {
            y: {
                min: 0,     // Minimum value for Y-axis (0%)
                max: 100,   // Maximum value for Y-axis (100%)
                ticks: {
                    // Callback to format tick labels as percentages
                    callback: function(value, index, values) {
                        return `${value}%`;
                    }
                }
            }
        },
        // Include any other options you need for the graph
    };

    const filteredVegetationValues = [];
const filteredVegetationDates = [];
let discardedDates = [];

vegetationValue.forEach((value, index) => {
  if (value !== 100) { // Check if the vegetation value is not 100
    filteredVegetationValues.push(value);
    filteredVegetationDates.push(vegetationDates[index]); // Use the same index to keep dates aligned
  }
  else {
    // Log the date being discarded because the value is 100
    discardedDates.push(vegetationDates[index]);
    //console.log("Discarding date:", vegetationDates[index], "with vegetation value of 100");
  }
});
//console.log("discarded dates array:", discardedDates)
discardedDates = discardedDates.map(date => `2024-${date}`);
    const graphData = {
        labels: filteredVegetationDates,
        datasets: [
          {
            label: 'Actual Vegetation',
            data: filteredVegetationValues,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }
        ]
    };



    const handleDateChangePicker = (newValue) => {
        //console.log("Date picker value:", newValue);
        setValue(newValue);
    };

    //Compare View Functions

    const [selectedDatasetCompare, setSelectedDatasetCompare] = useState(null);

    const [indexTypeCompare, setIndexTypeCompare] = useState('ndvi');

    const [dateCompare, setDateCompare] = useState(null);

    const handleChangeIndexCompare = (event) => {
        setIndexTypeCompare(event.target.value);
    };

    const handleDateChangePickerCompare = (newDateCompare) => {
        //console.log("Date picker value:", newDateCompare);
        setDateCompare(newDateCompare);
    };

    useEffect(() => {
        //console.log("Date or Index Type changed");
        const formattedDate = dateCompare ? dateCompare.format('YYYY-MM-DD') : null;
        //console.log('Fetching data for date:', dateCompare, 'and index type:', indexTypeCompare);
    
        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });
    
                let url = `https://nabuapplication.com/api/satelliteimage/${id}`;
                const params = new URLSearchParams();
    
                if (formattedDate) {
                    params.append('date', formattedDate);
                }
                if (indexTypeCompare){
                params.append('index_type', indexTypeCompare);
                }
    
                const response = await fetch(`${url}?${params.toString()}`, { headers });
                const data = await response.json();
    
                setSelectedDatasetCompare(data[0]); // Set the dataset based on the fetched data
                //console.log("Fetched data:", data);
    
            } catch (e) {
                setError(e);
            }
        }
    
        if (dateCompare || indexTypeCompare) {
            fetchData();
        }
    }, [dateCompare, indexTypeCompare]); // Depend on both value and indexType

    // Compare View Functions End
       //Compare2 View Functions

       const [selectedDatasetCompare2, setSelectedDatasetCompare2] = useState(null);

       const [indexTypeCompare2, setIndexTypeCompare2] = useState('ndvi');
   
       const [dateCompare2, setDateCompare2] = useState(null);
   
       const handleChangeIndexCompare2 = (event) => {
           setIndexTypeCompare2(event.target.value);
       };
   
       const handleDateChangePickerCompare2 = (newDateCompare) => {
           //console.log("Date picker value:", newDateCompare);
           setDateCompare2(newDateCompare);
       };
   
       useEffect(() => {
           //console.log("Date or Index Type changed");
           const formattedDate = dateCompare2 ? dateCompare2.format('YYYY-MM-DD') : null;
           //console.log('Fetching data for date:', dateCompare2, 'and index type:', indexTypeCompare2);
       
           async function fetchData() {
               try {
                   const headers = new Headers({
                       'Authorization': `Token ${token}`
                   });
       
                   let url = `https://nabuapplication.com/api/satelliteimage/${id}`;
                   const params = new URLSearchParams();
       
                   if (formattedDate) {
                       params.append('date', formattedDate);
                   }
                   if (indexTypeCompare2){
                   params.append('index_type', indexTypeCompare2);
                   }
       
                   const response = await fetch(`${url}?${params.toString()}`, { headers });
                   const data = await response.json();
       
                   setSelectedDatasetCompare2(data[0]); // Set the dataset based on the fetched data
                   //console.log("Fetched data:", data);
       
               } catch (e) {
                   setError(e);
               }
           }
       
           if (dateCompare2 || indexTypeCompare2) {
               fetchData();
           }
       }, [dateCompare2, indexTypeCompare2]); // Depend on both value and indexType
   
       // Compare2 View Functions End

    const [indexType, setIndexType] = useState('ndvi');

    const handleChangeIndex = (event) => {
        setIndexType(event.target.value);
    };

     // Gradient color map based on index type
     const colorMap = {
        ndvi: 'linear-gradient(to right, rgba(215, 25, 28, 1) 0%, rgba(223, 55, 42, 1) 5%, rgba(230, 85, 56, 1) 10%, rgba(238, 114, 69, 1) 15%, rgba(245, 144, 83, 1) 20%, rgba(252, 171, 98, 1) 25%, rgba(253, 189, 116, 1) 30%, rgba(254, 206, 134, 1) 35%, rgba(254, 223, 153, 1) 40%, rgba(254, 238, 171, 1) 45%, rgba(250, 249, 185, 1) 50%, rgba(237, 247, 184, 1) 55%, rgba(221, 241, 180, 1) 60%, rgba(205, 235, 175, 1) 65%, rgba(187, 227, 170, 1) 70%, rgba(169, 219, 166, 1) 75%, rgba(145, 203, 169, 1) 80%, rgba(120, 185, 173, 1) 85%, rgba(94, 167, 177, 1) 90%, rgba(69, 149, 182, 1) 95%, rgba(43, 131, 186, 1) 100%)', // NDVI color map
        ndre: 'linear-gradient(to right, rgba(0, 0, 4, 1) 0%, rgba(7, 6, 28, 1) 5%, rgba(21, 14, 55, 1) 10%, rgba(38, 17, 86, 1) 15%, rgba(59, 15, 111, 1) 20%, rgba(80, 18, 123, 1) 25%, rgba(100, 26, 128, 1) 30%, rgba(120, 34, 130, 1) 35%, rgba(140, 41, 129, 1) 40%, rgba(161, 48, 126, 1) 45%, rgba(182, 55, 122, 1) 50%, rgba(202, 62, 114, 1) 55%, rgba(221, 74, 105, 1) 60%, rgba(237, 89, 96, 1) 65%, rgba(246, 111, 92, 1) 70%, rgba(251, 135, 97, 1) 75%, rgba(254, 159, 109, 1) 80%, rgba(254, 183, 125, 1) 85%, rgba(254, 206, 145, 1) 90%, rgba(253, 230, 167, 1) 95%, rgba(255, 255, 255, 1) 100%)',   // NDRE color map
        ndmi: 'linear-gradient(to right, rgba(247, 251, 255, 1) 0%, rgba(237, 245, 252, 1) 5%, rgba(228, 239, 249, 1) 10%, rgba(218, 233, 246, 1) 15%, rgba(209, 226, 243, 1) 20%, rgba(200, 220, 240, 1) 25%, rgba(186, 214, 235, 1) 30%, rgba(170, 207, 229, 1) 35%, rgba(154, 200, 224, 1) 40%, rgba(134, 189, 220, 1) 45%, rgba(115, 178, 216, 1) 50%, rgba(98, 168, 210, 1) 55%, rgba(82, 157, 204, 1) 60%, rgba(66, 146, 198, 1) 65%, rgba(53, 133, 191, 1) 70%, rgba(41, 121, 185, 1) 75%, rgba(29, 108, 177, 1) 80%, rgba(18, 94, 166, 1) 85%, rgba(8, 81, 156, 1) 90%, rgba(8, 64, 131, 1) 95%, rgba(0, 0, 0, 1) 100%)'   // NDMI color map
        // Add more color maps for other index types if needed
    };

    useEffect(() => {
        //console.log("Date or Index Type changed");
        const formattedDate = value ? value.format('YYYY-MM-DD') : null;
        //console.log('Fetching data for date:', value, 'and index type:', indexType);
    
        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });
    
                let url = `https://nabuapplication.com/api/satelliteimage/${id}`;
                const params = new URLSearchParams();
    
                if (formattedDate) {
                    params.append('date', formattedDate);
                }
                if (indexType){
                params.append('index_type', indexType);
                }
    
                const response = await fetch(`${url}?${params.toString()}`, { headers });
                const data = await response.json();
    
                setSelectedDataset(data[0]); // Set the dataset based on the fetched data
                //console.log("Fetched data:", data);
    
            } catch (e) {
                setError(e);
            }
        }
    
        if (value || indexType) {
            fetchData();
        }
    }, [value, indexType]); // Depend on both value and indexType


    // Etc fetchhhhhhhh

    const [Etc, setEtc] = useState(null);

    useEffect(() => {
        if (value) {
            //console.log("valuechanged")
            const formattedDate = value.format('YYYY-MM-DD');
            async function fetchData() {
                try {
                    const headers = new Headers({
                        'Authorization': `Token ${token}`
                    });

                    const response = await fetch(`https://nabuapplication.com/api/etc/?field_info_id=${id}&date=${formattedDate}`, { headers });
                    const data = await response.json();

                    // Assuming the API returns the dataset for the selected date
                    setEtc(data[0]);    // this is the element that we are rendering !!!!!!!!!
                    //console.log("fetched etc")
                    //console.log(data)
                    //console.log("assigned etc")
                    //console.log(Etc)
                    

                } catch (e) {
                    setError(e);
                }
            }
            
            fetchData();
        }
    },  [value, indexType]);

    ////
    

  
    
    
    /*
    useEffect(() => {
        if (value) {
            console.log("valuechanged")
            const formattedDate = value.format('YYYY-MM-DD');
            async function fetchData() {
                try {
                    const headers = new Headers({
                        'Authorization': `Token ${token}`
                    });

                    const response = await fetch(`https://nabuapplication.com/api/satelliteimage/${id}?date=${formattedDate}&index_type=${indexType}`, { headers });
                    const data = await response.json();

                    // Assuming the API returns the dataset for the selected date
                    setSelectedDataset(data[0]);    // this is the element that we are rendering !!!!!!!!!
                    console.log("fetched")
                    console.log(data)
                    console.log("fetched")

                } catch (e) {
                    setError(e);
                }
            }
            
            fetchData();
        }
    },  [value, indexType]); // Effect runs when 'value' changes  */

    //console.log(datasetsDate)

    
    const [typeSelection, setTypeSelection] = useState('');
    // Handler for when a date is selected from the dropdown
    // Sort datasets by date in descending order and get the latest date
    const latestDate = datasets.sort((a, b) => new Date(b.date) - new Date(a.date))[0]?.date;
    //console.log("latest date")
    //console.log(latestDate)
    // Initialize the state with the latest date
    const [selecedDateInfo, setSelectedDateInfo] = useState(latestDate || null);
    //const [selecedDateInfo, setSelectedDateInfo] = useState(null)
    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        //console.log(selectedDate)
        setSelectedDateInfo(selectedDate)
        const dataset = datasets.find(d => d.date === selectedDate);
        //console.log("here")
        //console.log(dataset)
        setSelectedDataset(dataset);
    }; 

    const xUndefined = (e) => {

    }; 

    //const latestDate = datasetsDate.sort()[datasetsDate.length - 1];

    
    

    const dateObjects = datasetsDate.map(dateStr => new Date(dateStr));
    //console.log(dateObjects)

    const isDateAvailable = (date) => {
            // Create a new Date object to avoid mutating the original date
        let adjustedDate = new Date(date);

        // Subtract one day
        adjustedDate.setDate(adjustedDate.getDate() + 1);
        const formattedDate = adjustedDate.toISOString().split('T')[0];
        //return datasetsDate.includes(formattedDate);
        return datasetsDate.includes(formattedDate) && !discardedDates.includes(formattedDate);
    };

    const [showModal, setShowModal] = useState(false);

    const handleSettings = () => {
        setShowModal(true);
      };
      
      const handleCloseModal = () => {
        setShowModal(false);
      };
      
 

    return (
        <div>
            <MediaQuery minWidth={800}>
            <Box>
            <div className='titleSep'>
            <div className='WStitle'>{dataField ? dataField.name : 'Loading...'} {dataField ? (dataField.size ? dataField.size : '(Insert Size)') : ''}  Ha</div>
            <div className='iconWrap' onClick={handleSettings}>
                <SettingsOutlinedIcon></SettingsOutlinedIcon>
              </div>
            {showModal && (
        <Modal onClose={handleCloseModal}>
          {/* Content of the modal, such as the settings form */}
          {/* Add your settings form components here */}
        </Modal>
      )}
      </div>
                <div className='sattImageMain'>
                    <div className='sattImageImaPart'>
                        <div className='sattImaDropDown'>
                        <div></div>
            
                        <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select View</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Select View"
                        onChange={handleChangeView}
                        >
                        <MenuItem value={'10'}>Single View</MenuItem>
                        <MenuItem value={'20'}>Compare View</MenuItem>
                        
                        </Select>
                
                    </FormControl>
            <div></div>
    
            <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Index</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={indexType}
                            label="Select View"
                            onChange={handleChangeIndex}
                        >
                            <MenuItem value={'ndvi'}>NDVI</MenuItem>
                            <MenuItem value={'ndre'}>NDRE</MenuItem>
                            <MenuItem value={'ndmi'}>NDMI</MenuItem>
                        </Select>
                
                    </FormControl>
            
            <div></div>
            
                <div>
                    {/* Content to show when checkbox is checked */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/*<DatePicker
                        label="Controlled picker"
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    /> */}
                        <DatePicker
                        label="Select a Date"
                        value={value}
                        onChange={handleDateChangePicker}
                     
                        renderInput={(params) => <TextField {...params} />}
                        shouldDisableDate={(date) => !isDateAvailable(date)}
                        
                        
                    />
                       {/*onChange={(newValue) => {
                            setValue(newValue);
                            handleDateChangePicker
                            
                            const formattedDate = newValue.format('YYYY-MM-DD');
                            console.log(formattedDate)
                            const dataset = datasets.find(d => d.date === formattedDate);
                            setSelectedDataset(dataset);
                        }}
                    */}
                    </LocalizationProvider>
             
                </div>
            
                <FormGroup>
                <FormControlLabel
                    required
                    control={<Checkbox checked={isNormChecked} onChange={handleCheckboxNormChange} />}
                    label="Norm"
                />
            </FormGroup>
       
        </div>
            <div></div>
     {age === '10' && <div>
     {/* Check if selectedDataset exists */}
     {selectedDataset ? (
        /* Check if selectedDataset has image_data and bounds that are not default values */
        selectedDataset.image_data !== 'default_value'  && 
        Array.isArray(selectedDataset.bounds) && 
        selectedDataset.bounds.length === 2 &&
        selectedDataset.bounds[0] &&
        selectedDataset.bounds[1] ? (
            <div className='sattImageLeaflatMap'>
               <div style={{ textAlign: 'center' }}>
  {selectedDataset.cloud_percent > 15 ? (
    <div style={{ backgroundColor: '#fff3cd', color: '#856404', padding: '10px', border: '2px solid #ffeeba', borderRadius: '5px', margin: '10px auto' }}>
      Warning: Cloud percentage is high! 
    </div>
  ) : null}
</div>
            <MyMapComponent 
                key={selectedDataset.image_data} // Replace with a unique field from your dataset
                bounds={selectedDataset.bounds} 
                imageBase64={isNormChecked ? selectedDataset.image_data_norm : selectedDataset.image_data}
            />
            
            
              {/*<GradientLine />*/}
              <div className="gradient-line-container">
                <div className="label" style={{ left: '2%' }}>%0</div>
                <div className="label" style={{ left: '50%', transform: 'translateX(-50%)' }}>%50</div>
                <div className="label" style={{ right: '1%' }}>%100</div>
                <div className="gradient-line" style={{ background: colorMap[indexType] }}></div>
            </div>
              
            </div>
        ) : (
            <p>There is no data recorded for this day or the dataset is incomplete.</p>
        )
    ) : (
        <div style={parentStyle}>
        <CircularProgress />
        {/*<p>If it takes more than usual, Contact with Us</p>*/}
        </div>
    )}
    </div>}
    {// up to here is single view and next part is for compare view 
    }
    {age === '20' && <div className='sattImageLeaflatMapCompare'>
    {selectedDataset ? (
        /* Check if selectedDataset has image_data and bounds that are not default values */
        selectedDataset.image_data !== 'default_value'  && 
        Array.isArray(selectedDataset.bounds) && 
        selectedDataset.bounds.length === 2 &&
        selectedDataset.bounds[0] &&
        selectedDataset.bounds[1] ? (
            <div>
    
                    
                    
            <div className='sattImageLeaflatMap'>
                {/*<CompareView  />  */}


                {selectedDatasetCompare ? (
                <MyMapComponent
                        bounds={selectedDatasetCompare.bounds}
                        imageBase64={selectedDatasetCompare.image_data}
                    /> ):(<MyMapComponent
                        bounds={selectedDataset.bounds}
                        imageBase64={selectedDataset.image_data}
                    />)}
                    {/*  Upper Part, Here avoiding to show nothing at the begining of compare view */}
                         <div className=' compareView_selections'>
                         <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Index</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={indexTypeCompare}
                            label="Select View"
                            onChange={handleChangeIndexCompare}
                        >
                            <MenuItem value={'ndvi'}>NDVI</MenuItem>
                            <MenuItem value={'ndre'}>NDRE</MenuItem>
                            <MenuItem value={'ndmi'}>NDMI</MenuItem>   
                        </Select>
                
                    </FormControl>
                </div>
                <div className='compareView_selectionsDate'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                        label="DD/MM/YYYY"
                        value={dateCompare}
                        onChange={handleDateChangePickerCompare}
                     
                        renderInput={(params) => <TextField {...params} />}
                        shouldDisableDate={(date) => !isDateAvailable(date)}
                        
                        
                    />
                    </LocalizationProvider>
                    </div>
                
                {/* Up to here can be combined back with comapreView element*/ }
           
            </div>
            </div>
        ) : (
            <p>There is no data recorded for this day or the dataset is incomplete.</p>
        )
    ) : (
        <p>Please select a date from the dropdown.</p>
    )}
    <div></div>
    {selectedDataset ? (
        /* Check if selectedDataset has image_data and bounds that are not default values */
        selectedDataset.image_data !== 'default_value'  && 
        Array.isArray(selectedDataset.bounds) && 
        selectedDataset.bounds.length === 2 &&
        selectedDataset.bounds[0] &&
        selectedDataset.bounds[1] ? (
            <div className='sattImageLeaflatMap'>
                 {/*<CompareView  />  */}


                 {selectedDatasetCompare2 ? (
                <MyMapComponent
                        bounds={selectedDatasetCompare2.bounds}
                        imageBase64={selectedDatasetCompare2.image_data}
                    /> ):(<MyMapComponent
                        bounds={selectedDataset.bounds}
                        imageBase64={selectedDataset.image_data}
                    />)}
                    {/*  Upper Part, Here avoiding to show nothing at the begining of compare view */}
                         <div className=' compareView_selections'>
                         <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Index</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={indexTypeCompare2}
                            label="Select View"
                            onChange={handleChangeIndexCompare2}
                        >
                            <MenuItem value={'ndvi'}>NDVI</MenuItem>
                            <MenuItem value={'ndre'}>NDRE</MenuItem>
                            <MenuItem value={'ndmi'}>NDMI</MenuItem>
                        </Select>
                
                    </FormControl>
                </div>
                <div className='compareView_selectionsDate'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                        label="DD/MM/YYYY"
                        value={dateCompare2}
                        onChange={handleDateChangePickerCompare2}
                     
                        renderInput={(params) => <TextField {...params} />}
                        shouldDisableDate={(date) => !isDateAvailable(date)}
                        
                        
                    />
                    </LocalizationProvider>
                    </div>
                
                {/* Up to here can be combined back with comapreView element*/ }
                
            </div>
        ) : (
            <p>There is no data recorded for this day or the dataset is incomplete.</p>
        )
    ) : (
        <p>Please select a date from the dropdown.</p>
    )}
        {/*You selected Twenty*/}</div>}
    </div>
    <div></div>
    <div className='sattImaDataPart'>
        <div className='sattImaDataPartWrap'>
          <div className='sattImageTitles'>
          {selectedDataset ? (
            <div className='dataCard'>
                <div>
                    <div className='dataCardInnerElements'>Crop</div>
                    <strong>
                    <div className='dataCardDataElements'>{dataField ? (dataField.crop_type ? dataField.crop_type : 'None') : ''}</div>
                    </strong>
                    <br></br>
                    <div className='dataCardInnerElements'>Average Vegetation</div>
                    <strong>
                    <div className='dataCardDataElements'>{selectedDataset.average < 0 
                                    ? "0%" 
                                    : `${Math.round(selectedDataset.average * 100)}%`}</div>
                    </strong>
                    <br></br>
                    <div className='dataCardInnerElements'>Min</div>
                    <strong>
                    <div className='dataCardDataElements'>{selectedDataset.minimum < 0 
                                    ? "0%" 
                                    : `${Math.round(selectedDataset.minimum * 100)}%`}
                    </div>
                    </strong>
                </div>
                <div>           
                    <div className='dataCardInnerElements'>Etc</div>
                    {Etc ? (
                        Etc.Etc === 99999 ? (
                            <ReportProblemIcon style={{ color: 'orange', paddingTop: '8px', paddingLeft:'10px', paddingBottom:'8px', fontSize: '3.5vh' }} />
                          ):
                    <strong>
                        
                    <div className='dataCardDataElements'>{Etc.Etc.toFixed(2)}mm</div>  
                    </strong> ) : ( <strong>
                        
                        <div className='dataCardDataElements'>-</div>  
                        </strong>)}
                    <br></br>
                    <div className='dataCardInnerElements'>Median Vegetation</div>
                    <strong>
                    <div className='dataCardDataElements'>{selectedDataset.median < 0 
                                    ? "0%" 
                                    : `${Math.round(selectedDataset.median * 100)}%`}</div>
                    </strong>
                    <br></br>
                    <div className='dataCardInnerElements'>Max</div>
                    <strong>
                    <div className='dataCardDataElements'>{selectedDataset.maximum < 0 
                                    ? "0%" 
                                    : `${Math.round(selectedDataset.maximum * 100)}%`}</div>
                    </strong>
                </div>

            </div>
          ): (
            <p>No Data Yet</p>
        )}
         
          </div>
          {/*selectedDataset ? (
          <div className='sattImageGraphs'>
         
          <h3> Average Vegetation: {selectedDataset.average}</h3> 
          <h3> Max: {selectedDataset.maximum}</h3>
          <h3> Min: {selectedDataset.minimum}</h3>
          <h3> Median: {selectedDataset.median}</h3>
          
          
          </div>
          ):
          (
            <p>There is no data recorded for this day or the dataset is incomplete.</p>
          )*/}
          <br></br>
          <FormControl style={{ width: '94%', margin: 'auto'  }}>
                        <InputLabel id="demo-simple-select-label">Select Curve</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={curveValue}
                        label="Select Curve"
                        onChange={xUndefined}
                        >
                        <MenuItem value={'10'}>Vegetation Analysis</MenuItem>
                        <MenuItem value={'20'}>Moisture Analysis</MenuItem>
                        
                        </Select>
                
                    </FormControl>
          <div className='sattImageGraphs' style={{ height: '280px' }}>
            <br></br>
          <Line data={graphData} options={graphOptions}  />
          <div class="element-to-center">
          <select value={selectedYear} onChange={handleYearChange}>
                {[2019, 2020, 2021, 2022, 2023, 2024].map(year => (
                    <option key={year} value={year}>{year}</option>
                ))}
            </select>
            </div>
            
            
          </div>
        </div>
      </div>  
    </div>
    
  {/* Mobile version starts here */}
    </Box>
    </MediaQuery>
    <MediaQuery maxWidth={799}>
        <div className=''>
        <Box>
        <div className='titleSep'>
            <div className='WStitleMobil'>{dataField ? dataField.name : 'Loading...'} {dataField ? (dataField.size ? dataField.size : '(Insert Size)') : ''}  Ha</div>
            <div className='iconWrap' onClick={handleSettings}>
                <SettingsOutlinedIcon></SettingsOutlinedIcon>
              </div>
            {showModal && (
        <Modal onClose={handleCloseModal}>
          {/* Content of the modal, such as the settings form */}
          {/* Add your settings form components here */}
        </Modal>
      )}
      </div>
        
                <div className='sattImageMainMobil'>
                    <div className='sattImageImaPart'>
                        <div className='sattImaDropDown'>
                        <div></div>
            
                        <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select View</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Select View"
                        onChange={handleChangeView}
                        >
                        <MenuItem value={'10'}>Single View</MenuItem>
                        <MenuItem value={'20'}>Compare View</MenuItem>
                        
                        </Select>
                
                    </FormControl>
            <div></div>
    
            <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Index</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={indexType}
                            label="Select View"
                            onChange={handleChangeIndex}
                        >
                            <MenuItem value={'ndvi'}>NDVI</MenuItem>
                            <MenuItem value={'ndre'}>NDRE</MenuItem>
                            <MenuItem value={'ndmi'}>NDMI</MenuItem>
                        </Select>
                
                    </FormControl>
            
            <div></div>
            
                <div>
                    {/* Content to show when checkbox is checked */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/*<DatePicker
                        label="Controlled picker"
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    /> */}
                        <DatePicker
                        label="Select a Date"
                        value={value}
                        onChange={handleDateChangePicker}
                     
                        renderInput={(params) => <TextField {...params} />}
                        shouldDisableDate={(date) => !isDateAvailable(date)}
                        
                        
                    />
                       {/*onChange={(newValue) => {
                            setValue(newValue);
                            handleDateChangePicker
                            
                            const formattedDate = newValue.format('YYYY-MM-DD');
                            console.log(formattedDate)
                            const dataset = datasets.find(d => d.date === formattedDate);
                            setSelectedDataset(dataset);
                        }}
                    */}
                    </LocalizationProvider>
                    <FormControlLabel
                    required
                    control={<Checkbox checked={isNormChecked} onChange={handleCheckboxNormChange} />}
                    label="Norm"
                />
                </div>
            
      
       
        </div>
            <div></div>
     {age === '10' && <div>
     {/* Check if selectedDataset exists */}
     {selectedDataset ? (
        /* Check if selectedDataset has image_data and bounds that are not default values */
        selectedDataset.image_data !== 'default_value'  && 
        Array.isArray(selectedDataset.bounds) && 
        selectedDataset.bounds.length === 2 &&
        selectedDataset.bounds[0] &&
        selectedDataset.bounds[1] ? (
            <div className='sattImageLeaflatMap'>
            <MyMapComponent 
                bounds={selectedDataset.bounds} 
                
                imageBase64={isNormChecked ? selectedDataset.image_data_norm : selectedDataset.image_data}
            />
            <br></br>
              <GradientLine />
              <br></br>
              <FormControl style={{ width: '94%', margin: 'auto'  }}>
                        <InputLabel id="demo-simple-select-label">Select Curve</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={curveValue}
                        label="Select Curve"
                        onChange={xUndefined}
                        >
                        <MenuItem value={'10'}>Vegetation Analysis</MenuItem>
                        <MenuItem value={'20'}>Moisture Analysis</MenuItem>
                        
                        </Select>
                
                    </FormControl>
          <div className='sattImageGraphs' style={{ height: '200px' }}>
            <br></br>
          <Line data={graphData} options={graphOptions}  />
          <div class="element-to-center">
          <select value={selectedYear} onChange={handleYearChange}>
                {[2019, 2020, 2021, 2022, 2023, 2024].map(year => (
                    <option key={year} value={year}>{year}</option>
                ))}
            </select>
            </div>
            
            
          </div>
            </div>
        ) : (
            <p>There is no data recorded for this day or the dataset is incomplete.</p>
        )
    ) : (
        <p>Please select a date from the dropdown.</p>
    )}
    </div>}
    {// up to here is single view and next part is for compare view 
    }
    {age === '20' && <div className='sattImageLeaflatMapCompare'>
    {selectedDataset ? (
        /* Check if selectedDataset has image_data and bounds that are not default values */
        selectedDataset.image_data !== 'default_value'  && 
        Array.isArray(selectedDataset.bounds) && 
        selectedDataset.bounds.length === 2 &&
        selectedDataset.bounds[0] &&
        selectedDataset.bounds[1] ? (
            <div>
    
                    
                    
            <div className='sattImageLeaflatMap'>
                <CompareView  />
           
            </div>
            </div>
        ) : (
            <p>There is no data recorded for this day or the dataset is incomplete.</p>
        )
    ) : (
        <p>Please select a date from the dropdown.</p>
    )}
    <div></div>
    {selectedDataset ? (
        /* Check if selectedDataset has image_data and bounds that are not default values */
        selectedDataset.image_data !== 'default_value'  && 
        Array.isArray(selectedDataset.bounds) && 
        selectedDataset.bounds.length === 2 &&
        selectedDataset.bounds[0] &&
        selectedDataset.bounds[1] ? (
            <div className='sattImageLeaflatMap'>
                <CompareView  />
                
            </div>
        ) : (
            <p>There is no data recorded for this day or the dataset is incomplete.</p>
        )
    ) : (
        <p>Please select a date from the dropdown.</p>
    )}
        {/*You selected Twenty*/}</div>}
    </div>
    </div>
        </Box>
        
        </div>
        
    </MediaQuery>
</div>
    );
    
}

export default SattImageMainUpdate;
