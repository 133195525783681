import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom'
import { getFields, getFarms, getDevices } from '../services/field_services';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

import { useAuth } from "../hooks/useAuth";
import { useToken } from './useToken';

function ListOfFields() {
  const token = useToken()
  //console.log(token)


  const [showMenu, setShowMenu] = React.useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);

  };

  const [dataFields, setDataFields] = useState(null);
  /*useEffect(()=>{
    setLoading(true);
    const getData1 = async () => {
      
        await getFields(token).then(dataFields => {
        setLoading(false);
        setDataFields(dataFields);
    } )
    }
    getData1();
},  []   ) */
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const headers = new Headers({
          'Authorization': `Token ${token}`
        });

        const response = await fetch(`https://nabuapplication.com/api/fields/`, { headers });
        const data = await response.json();
        setDataFields(data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    if (token) {
      fetchData();
    }
  }, [token]);


  const [selectedFarm, setSelectedFarm] = useState("");
  function toggleMenu2(farmId) {
    if (selectedFarm === farmId) {
      // If the clicked farm is already the selected one, deselect it and hide the menu
      setSelectedFarm(null);
      setShowMenu(false);
    } else {
      // If another farm is clicked, select it and show the menu
      setSelectedFarm(farmId);
      setShowMenu(true);
    }
  }
  const [data, setData] = useState(null);
  const [deviceData, setDeviceData] = useState(null);
  const [showMenuDevice, setShowMenuDevice] = React.useState(false);
  const [selectedField, setSelectedField] = useState("");
  const [showFarmDevices, setShowFarmDevices] = React.useState(false);
  /*function toggleMenuDevice(fieldId) {
    setSelectedField(fieldId);
    setShowMenuDevice(!showMenuDevice);
  }*/
  function toggleMenuDevice(fieldId) {
    if (selectedField === fieldId) {
      // If the clicked field is already the selected one, deselect it and hide the menu
      setSelectedField(null);
      setShowMenuDevice(false);
    } else {
      // If another field is clicked, select it and show the menu
      setSelectedField(fieldId);
      setShowMenuDevice(true);
    }
  }
  const toggleShowDevices = () => {
    setShowFarmDevices(!showFarmDevices);
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { authData, setAuth } = useAuth();

  /* useEffect(()=>{
     setLoading(true);
     const getData = async () => {
      
         await getFarms(token).then(data => {
         setLoading(false);
         setData(data);
     } )
     }
     getData();
 },  []   ) */


  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const headers = new Headers({
          'Authorization': `Token ${token}`
        });

        const response = await fetch(`https://nabuapplication.com/api/farms/`, { headers });
        const data = await response.json();
        setData(data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    if (token) {
      fetchData();
    }
  }, [token]);

  /*useEffect(()=>{
    setLoading(true);
    
    const getData2 = async () => {
   
        await getDevices(token).then(deviceData => {
        setLoading(false);
        setDeviceData(deviceData);
    } )
    }
    getData2();
  },  []   ) */
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const headers = new Headers({
          'Authorization': `Token ${token}`
        });

        const response = await fetch(`https://nabuapplication.com/api/devices/`, { headers });
        const data = await response.json();
        setDeviceData(data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    if (token) {
      fetchData();
    }
  }, [token]);

  


  if (error) return <h1>Error</h1>
  if (loading) return
  <Box sx={{ display: 'flex' }}>
    <CircularProgress />
  </Box>
  {/*<h1>Loading...</h1>*/ }

  const user_id_value = authData.user.id
  const farm_info_value = 71

  //const sortedDevices = devices.sort((a, b) => a.id - b.id);


  return (
    <div>
      {data ? data.filter(data => data.user_id === user_id_value).map(data => {
        return (
          <div key={data.id}>
            <div
              className={selectedFarm === data.id ? '' : ''}
              style={{ display: 'flex', alignItems: 'center', borderLeft: selectedFarm === data.id ? '4px solid green' : 'none', textDecoration: selectedFarm === data.id ? 'underline' : 'none' }}>
              {selectedFarm === data.id ? <PlaylistRemoveIcon /> : <PlaylistAddIcon />}
              <Link to={`/home/farm/${data.id}`} onClick={() => toggleMenu2(data.id)}>
                {data.name}
              </Link>
            </div>
            {selectedFarm === data.id && (
              <div>
                <div className='subFields' onClick={toggleShowDevices}>
                  {/* Toggle icons based on showFarmDevices state */}
                  {showFarmDevices ? <PlaylistRemoveIcon /> : <div className='denemeSidebarTtls'> <PlaylistAddIcon /> </div>}
                  <span style={{ marginLeft: '' }}>Devices</span>
                </div>
                {showFarmDevices && deviceData && dataFields
                  .filter(field => field.farm_info === selectedFarm) // Filter fields by selected farm
                  .map(field => field.id) // Get field IDs
                  .map(fieldId => (
                    deviceData
                      .filter(device => device.field_info === fieldId) // Filter devices by field ID
                      .sort((a, b) => a.id - b.id)
                      .map(device => (
                        <Link key={device.id} to={
                          device.device_type === "inverter" ? `/home/inverter/${device.id}` :
                          device.device_type === "pivot" ? `/home/pivotcontrol/${device.id}` :
                          device.device_type === "moisture" ? `/home/moisture/${device.id}` :
                          device.device_type === "lora_weather_station" ? `/home/weatherlora/${device.id}` :
                          `/home/weather/${device.id}`
                        }>
                          <div className="subDevices">
                            {console.log("field data", dataFields)}
                            {console.log("farm id", data.id)}
                            {console.log("device fetch deviceData", deviceData)}
                            {console.log("device device", device)}

                            {device.name}
                            {/*device.id*/}
                          </div>
                        </Link>
                      ))
                  ))}
                {dataFields
                  .filter(dataField => dataField.farm_info === data.id)
                  .map(dataField => (
                    <div
                      className={selectedField === dataField.id ? '' : 'subFields'}
                      style={{ display: '', alignItems: '', borderLeft: selectedField === dataField.id ? '2px solid green' : 'none', }}>
                      {selectedField === dataField.id ? <PlaylistRemoveIcon /> : <div className='denemeSidebarTtls'> <PlaylistAddIcon /> </div>}
                      <Link key={dataField.id} to={`/home/field/${dataField.id}`} onClick={() => toggleMenuDevice(dataField.id)}>
                        <h9 className="subFields" key={dataField.name}>
                          {dataField.name}
                          {selectedField === dataField.id && showMenuDevice && (
                            <div>
                              {deviceData
                                .filter(deviceDatas => deviceDatas.field_info === dataField.id)
                                .map(deviceDatas => (
                                  <Link key={deviceDatas.id} to={
                                    deviceDatas.device_type === "inverter" ? `/home/inverter/${deviceDatas.id}` :
                                    deviceDatas.device_type === "moisture" ? `/home/moisture/${deviceDatas.id}` :
                                    deviceDatas.device_type === "lora_weather_station" ? `/home/weatherlora/${deviceDatas.id}` :
                                    `/home/weather/${deviceDatas.id}`
                                  }>
                                    <h9 className="subDevices" key={deviceDatas.name}>
                                      {deviceDatas.name}
                                    </h9>
                                    <br></br>
                                  </Link>
                                ))}
                            </div>
                          )}
                        </h9>
                        <br></br>
                      </Link>
                    </div>
                  ))}
              </div>
            )}
          </div>
        );
      }) : null}
      {/*<h3>{selectedFarm}</h3>*/}
    </div>
  );
}

export default ListOfFields;



/*


import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'

function FetchData() {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(()=>{
    setLoading(true);
    const getData = async () => {
        await fetch ('http://127.0.0.1:8000/api/costumer/')
        .then(resp => resp.json())
        .then(data => {
            setData(data);
            setLoading(false);
            //console.log(data);
        }).catch(e => {
          setError(true);
          setLoading(false);
        })
    }
    getData();
},  []   )


if (error) return <h1>Error</h1>
if (loading) return <h1>Loading...</h1>

  return (
    <div>
        { data && data.map(data => {   // { means if and if data and then make after && part
            return <p key={data.id}>{data.first_name}</p>
        } )}
    </div>
  );
}

export default FetchData;

*/


