// useFarmData.js
import { useEffect, useState } from 'react';

export function useFarmDataDe(token) {
  const [dataF, setData] = useState(null);
  const [loadin, setLoading] = useState(false);
  const [erro, setError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const headers = new Headers({
          'Authorization': `Token ${token}`
        });

        const response = await fetch(`https://nabuapplication.com/api/farms/`, { headers });
        const data = await response.json();
        setData(data);
        if (data) {
          setData(data);
        }
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    if (token) {
      fetchData();
    }
  }, [token]);

  //console.log(dataF)

  return { dataF, loadin, erro };
}
