import React, {useState, useEffect} from "react"; 

//import {useCookies} from 'react-cookie';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';


import './auth.css';

import logo from '../assets/logo_nabu_login.png';
import SearchIcon from '@mui/icons-material/Search';

import PersonIcon from '@mui/icons-material/Person';
import HttpsIcon from '@mui/icons-material/Https';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
//import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

import { purple } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { auth } from "../services/user_services";
import {useAuth} from "../hooks/useAuth";
import {Link} from 'react-router-dom'
import { Routes, Route } from 'react-router-dom';
import HomePage from '../OldFilesWillBeDeletedOneDayIhope/homePage';


const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: purple[500],
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#11cb5f',
      },
    },
  });

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.35),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.45),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    marginBottom: 5,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '30ch',
      },
    },
  }));
  const StyledInputBasePass = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        //width: '24.5ch',
        width: '30ch',
      },
    },
  }));
 



function Auth() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { authData, setAuth} = useAuth();
    const [isLoginView, setIsLoginView] = useState(true);

    //const [token, setToken] = useCookies(['mr-token']);

    const [deneme, setdeneme] = useState("LOGIN")
    const [logout, setLogout] = useState("LOGOUT")
    const [continueTo, setContinueTo] = useState("Continue to Home Page")

    /*useEffect(() => {
        console.log(token);
        if(token['mr-token']) window.location.href = '/';
    }, [token])  */


    const isDisabled = username.length ===0 || password.length === 0;

    const loginClicked =()=> {
        if (username == "pivot" && password == "password")

        window.location.href = 'home'; 
        else if (username == "golf" &&  password == "password")
        window.location.href = '/mainpageGolf'; 
        else if ( username == "inverter" && password == "password")
        window.location.href = '/inverterPage'; 
       
      }

   

      const handleSubmit = async e => {
        e.preventDefault();
        try {
          const authData = await auth({username, password});
          setAuth(authData);
          window.location.href = 'home';
          console.log(authData);
      } catch (error) {
          alert(error.message);
      }
        /*
        const authData = await auth ({username, password})
        window.location.href = 'home'; 
        
        setAuth(authData);
        console.log(authData)*/
        //console.log(username, password)
      }

      const logOut = () => {
        setAuth(null);
      }
      const continueToFunc = () => {
        window.location.href = 'home';
      }

    return (
        <div className="Login_page">
            
            {isLoginView ? <img src={logo} className = "logo_login_page" />: <img src={logo} className = "logo_login_page" />}
            {isLoginView ? <h1 className="font-link_robota" >Login </h1>: <h1 className="font-link_robota" > Register</h1>}
            {!authData ? 
            <div>
            <form onSubmit={handleSubmit}>
            <div>            
                <Search>
                    <SearchIconWrapper>
                        <PersonIcon/>
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Username"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange= { evt => setUsername(evt.target.value)}
                        id="username" 
                        value ={username}


                        
                       
                />
                
           </Search></div>


           <div>
           <Search>
                <SearchIconWrapper>
                    <HttpsIcon/>
                </SearchIconWrapper>
                <StyledInputBasePass
                    placeholder="Password"
                    inputProps={{ 'aria-label': 'search' }}
                    id="outlined-adornment-password"
                    //type={values.showPassword ? 'text' : 'password'} 
                    type={'password'} 
                    value ={password}
                    onChange= { evt => setPassword(evt.target.value)}
                    //onChange= { handleChange("password") } 
                    
                    /*endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }*/
                />
           </Search>
           </div>
           

           <div className = "loginPage_Inner">
               <div>
                    <ThemeProvider theme={theme}>
                       
                    <h3 className = " forgot_pass_register_now">  
                        <Link to= {'/register'}>
                          <Typography> Register Now!</Typography> 
                        </Link>  
                          
                    </h3>   
                        
                    </ThemeProvider>
               </div>
               <div>
                    <h3 className = " forgot_pass">
                        <Typography  style={{ color: 'white' }}>Forgot password?</Typography>
                    </h3>

               </div>

              
           </div>

          
           <Button  style={{minWidth: '300px', borderRadius: 18, marginLeft: '30px'}} variant="contained" color="success" type='submit'>{deneme}         </Button> 
           </form>
           </div>
           :
           
           <div>
            <Link  to ={`/home/`}>    </Link>
           
             <h3 className="welcomeback">Welcome Back {authData.user.username}</h3>
             <Button  style={{minWidth: '300px', borderRadius: 18}} variant="contained" color="success" onClick={()=> continueToFunc()}>{continueTo}         </Button>
             <br></br>
             <br></br>
             <Button  style={{minWidth: '300px', borderRadius: 18}} variant="contained" color="success" onClick={()=> logOut()}>{logout}         </Button>

           </div>
           
          
}
           
{/*
           <Button onClick= {() => setdeneme(password)} style={{minWidth: '300px', borderRadius: 18}} variant="contained" color="success">{deneme}         </Button>*/ }

           {/*<div className= "login_button">
                            {isLoginView ? 
                        
                            <button color="#f194ff" onClick ={loginClicked} >Login</button>:
                            <button color="#f194ff" onClick ={registerClicked} disabled={isDisabled}>Register</button>
                            }
                        </div> */}


            
            {/*<div className="Login_page_first_element">
                <label className="new" htmlFor = "username"> </label> <br/>
                <input  id="username" type="text" placeholder="Usernamesss"  value ={username}
                        onChange= { evt => setUsername(evt.target.value)}
                
                
                /><br/>  
            </div> */}
        {/*<div className="Login_page_first_element">
            <label className="new" htmlFor= "password"> </label><br/>
            
            <input id= "password" type="password" placeholder="Password" value ={password}
                        onChange= { evt => setPassword(evt.target.value)}  ></input><br/>
                        <div className= "login_button">
                            {isLoginView ? 
                        
                            <button color="#f194ff" onClick ={loginClicked} disabled={isDisabled}>Login</button>:
                            <button color="#f194ff" onClick ={registerClicked} disabled={isDisabled}>Register</button>
                            }
                        </div>
                        
            
                        </div> */}
         
         {/*{isLoginView ? 
            <p onClick= {()=> setIsLoginView(false)}>You don't have an acoount? Register here!</p>:
            <p onClick= {()=> setIsLoginView(true)}>You already have an account? Login here!</p>
         } */}

       
    </div>
    )
}

export default Auth;