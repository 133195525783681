import '../App.css';
import React, { useEffect, useState } from 'react';

import Header from '../layoutElements/header';
import SideBar from '../layoutElements/sidebar';
import Main from '../layoutElements/main';
//import MySidebar from './assets/secondsidebar';
import Footer from '../layoutElements/footer';
import MobileSidebar from '../layoutElements/mobileSideBarElements/mobilesideBar';





function HomePage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App">
      {!isMobile && <Header />}

      {isMobile ? (
        // Mobile Version
        <div className="general_content-mobile">
          <MobileSidebar></MobileSidebar>
          {/*<Main />*/}
        </div>
      ) : (
        // Desktop Version
        <div className="general_content">
          <div></div>
          <SideBar />
          <Main />
          <div></div>
        </div>
      )}

      <Footer></Footer>
    </div>
  );
}

export default HomePage;
/*

  function HomePage() {

      return (
        
          <div className="App">
          <Header/>
          <div className='general_content'>
            <div></div>
            <SideBar/>
            <Main/>
            <div></div>
          </div>
          
          <Footer></Footer>

        </div>
      );
    }
    
    export default HomePage;
    */