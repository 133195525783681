
import React, {useState}  from 'react';
import FetchData from '../components/fetch_data';
import ListOfFields from '../components/listOfFields';
import Button from '@mui/material/Button';
import {Link, useParams} from 'react-router-dom'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';



function SideBar() {
  const [addButton, setAddButton] = useState("Add")
  const [addField, setAddField] = useState("Add Field")
  const [addFarm, setAddFarm] = useState("Add Farm")
  const logOut = () => {
    //window.location.href = 'home/addfield';
    //window.location.pathname = 'home/addfield';
  }
  const logOut2 = () => {
    //window.location.pathname = 'home/addfarm';
  }

  return (
    <div className="sidebar">
        <div className='sidebartitle'>Farm Area</div>
        <p className='sideBarMain'><ListOfFields/>
        <Link  to ={`/home/add`}> 
          <div className='addButton'>
            <Button  startIcon={<AddCircleOutlineRoundedIcon />} style={{minWidth: '30px', borderRadius: 18}} variant="contained" color="success" onClick={()=> logOut()}>{addButton}      </Button>
          </div>
        </Link>
        </p>
        {/*
        <br></br>
        <br></br>
        <br></br>
        <Link  to ={`/home/addfield`}> 
          <Button  style={{minWidth: '30px', borderRadius: 18}} variant="contained" color="success" onClick={()=> logOut()}>{addField}      </Button>
        </Link>
        <Link  to ={`/home/addfarm`}> 
          <Button  style={{minWidth: '30px', borderRadius: 18}} variant="contained" color="success" onClick={()=> logOut2()}>{addFarm}      </Button>
  </Link>*/}
    </div>
  );
}

export default SideBar;
